/* global Excel */
/*slint-disable */
// About this file:
// This file includes script of the default template to manage resources

import {
  actions,
  getDisableEvents as getGlobalDisableEvents,
  inputTypes,
  inputTypeTagPrefix,
  protectSheet,
  setDisableEvents as setGlobalDisableEvents,
  unprotectSheet,
  twoArraysToObject,
} from "@/utilities/template-common";

import {
  convertLetterToNumber,
  convertNumberToLetter,
  getDtVlList,
  getNamedRangeValue,
  compareCellAddress,
  nameRangeOnActivesheet,
  renameSheet,
  setRangeName,
  setRangeNameOnActivesheet,
  sortCellAddressByRow,
  addWorksheet,
} from "@/utilities/general-excel-functions";

import { get, countBy } from "lodash-es";

// ***
// *** Global Variables & Constants ***
// ***

// -- Rows --
const tagsRow = 0; // Row # of tags (for getCell() use tagsRow, for getRange() use tagsRow + 1)
const namesRow = 1; // Row # of attribute names (for getCell() use namesRow, for getRange() use namesRow + 1)
const labelsRow = 2; // Row of attribute labels (for getCell() use labelsRow, for getRange() use labelsRow + 1)
const firstDataRow = 3; // First row of data
const firstTableRow = 2; // First row of data table
const storageColHeaderRow = 1; // The row containing the header of storage columns
let lastTableRow = 2; // The last row of data table

// -- Columns --
const firstTableCol = "B"; // The first column of the data table
let lastTableCol = "B"; // The last column of the data table
const firstAttributeCol = 4; // The first attribute column
const idColumn = 1; // The ID column
const timestampColumn = 2; // The timestamp/Retrieve_at column
const actionsColumn = 3; // The actions column
const firstAvailableCol = 4; // The first available column
let availableCol = 5; // Available column, which icnrements when a columns is filled

// -- Ranges --
const rscNameCellAddress = "A1"; // The cell address of resource name
const rscRngName = "rng_rsc_name"; // The name of the range including the resource name
const fspNameCellAddress = "A2"; // The cell address of resource name
const fspRngName = "rng_fsp_name"; // The name of the range including the values of the filter, sort and pagination in the current sheet
const tagsRngName = "rng_attr_tags"; // The name of the range including attribute tags
const attrNamesRngName = "rng_attr_names"; // The name of the range including attribute names
const namesRngName = "rng_names"; // The name of the range including names
const cellsWithErrorRngName = "rng_cells_w_error"; // The range including the list of cells with error which occured after syncing with the API
const cellsWithErrorHeaderRngName = "rng_cells_w_error_h"; // The header of the column including the list of cells with error which occured after syncing with the API
const firstItemsColumnRngName = "rng_first_items_column"; // The name of the header range of the first column including items which is to the right of the table
const itemsRangePrefix = "ir_"; // The prefix of the name of ranges including items such as dropdown items

// -- Sheets --
const tempStorageSheetName = "Temp~Storage";

// -- Tags --
// Tags are used to specify properties of an attribute, for example,
//	+ Whether the attribute contains a dropdown
//	+ Whether it's hidden
//	+ Whether is has a formula
const tagSeparator = ";"; // Separate tags by this
const tagsIdn = Object.freeze({
  // List of tags identifying columns
  storageColumn: "storage_column", // The tag identifying the storage column
  itemsColumn: "items_column", // The tag identifying the column which includes the items of a dropdown or checkbox attribute
  itemsWithId: "items_with_id", // The tag of column which has dropdowns whose items have instance ID's
  dependableItemsWithId: "dependable_items_with_id", // The tag of column which has dropdowns whose items have instance ID's and are dependable on the value of another attribute
  itemsFromMultiResources: "items_from_multiple_resources", // This tag defines attributes with items which are seeded from multiple resources
  linkedToStrgCol: "linked_to_storage_column", // This tag identifies the columns which are linked to a storage column
  formula: "formula", // The tag of column whose cells have a formula
  replaceFormulaWithValue: "replace_formula_with_value", // This tag can be assigned to an attribute with a formula. If an attribute with this tag has a value, the value is displayed in the table; otherwise, the formula is displayed.
  hidden: "hidden", // The tag of columns which are hidden
  display: "display", // The tag of columns which are displayed
  hyperlink: "hyperlink", // The tag of columns which include Hyperlinks / URLs
  //checkboxCol: "checkbox
  // The tag of columns whose values are saved in the database.
  // For example some columns have formulas. Using this tag will save the columns as values (not formulas) in the database
  saveAsValue: "save_as_value",
  // The tag of columns whose texts are saved in the database.
  // For example some columns have dates. Using this tag will save column as text (not value) in the db
  saveAsText: "save_as_text",
  autoPopulateBO: "auto_populate_based_on_other", // The tag of columns which are auto populated based on the value of other attributes
  convertMinsToHrs: "convert_mins_to_hours", // The tag of columns whose values are in minutes but displayed as hours
  editOnAddin: "edit_on_addin", // The tag of columns whose value is edited via addin by clicking the Edit Cell button
  wrapTxt: "wrap_text", // The tag of columns whose text is wrapped
  relatedResource: "related_resource", // A column with the related_resource tag displays the information of the instances of the related resource. An example of information is the number of task_assignees which a task have.
  readOnly: "read_only", // Read-only columns
  booleanYesNo: "convert_zeroone_to_yesno", // For boolean attributes, its value is saved as 0/1 in the database. On Excel, display it as Yes,No
});

// -- Texts --
const idColHeader = "ID"; // The ID column header
const timestampColHeader = "Retrieve-At"; // The Timestamp column header
const actionsColHeader = "Action-Buttons"; // The actions button column header

const idColName = "id"; // The ID column name
const retrieveAtColName = "Retrieve~At"; // The Timestamp column header
const actionsColName = "action~buttons"; // The actions button column header

const placeholderOfCellToStCl = '="View/edit"'; // place holder for the cell whose value is stored in the related cell of its storage column
const editOnAddinCellPrefix = "View/Edit                   "; // The text before the value of cells with the editOnAddin tag

const itemsColumnTitlePostfix = " Items"; // The postfix for the title of columns incouding items, for example "assignee Items"

// -- Styling --
const hideBackendRanges = true; // If true, then hide the ranges that are for backend purposes and not used by users
const cellWithError_bgColor = "red"; // The BG color of cells with error

// -- Global variables to hold Excel data --
//const wbData = {}; // All data of the workbook is in this variable
const shData: {
  // A copy of the data on the active sheet is saved into this object
  name: string;
  resource: string;
  attributeNames: string[];
  attributeLabels: { [key: string]: string };
  tags: { [key: string]: string[] }; // The tags of each attribute column
  items: { [key: string]: string }; // Such as dropdown or checkbox items
  numberFormat: { [key: string]: string }; // The number format of each attribute column
  columnIndexes: { [key: string]: number }; // The column index of each attribute column
  data: string[][]; // The instances data. It's a two dimensional array: [rowNo][attributeName]
  storageColumnIndex: { [key: string]: number }; // The storage column index of each attribute
} = {
  // All data of the active sheet is in this variable
  name: "",
  resource: "",
  attributeNames: [],
  attributeLabels: {},
  tags: {}, // The tags of each attribute column
  items: {}, // Such as dropdown or checkbox items
  numberFormat: {}, // The number format of each attribute column
  columnIndexes: {}, // The column index of each attribute column
  data: [], // The instances data. It's a two dimensional array: [rowNo][attributeName]
  storageColumnIndex: {}, // The storage column index
};

// -- Others --
let anyHiddenRow = false; // If any row is hidden, this variable turns true
//let disableProtection = false; // If true, then sheets won't be protected

// Return whether any row in the data table is hidden for example due to filtering
export const hasAnyHiddenRow = () => {
  return anyHiddenRow;
};

// Get data of rows to sync with the database
export const getRowsToSync = async () => {
  return await Excel.run(async (context) => {
    //const start = Date.now(); // This line may be used for testing purposes to tack time
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const namesRng = sheet.getRange(namesRngName);
    const attNamesRange = sheet.getRange(attrNamesRngName); // The range containing attribute names
    const tagsRng = sheet.getRange(tagsRngName);

    // The data of table
    const dataTbl = sheet.tables.getItemAt(0);
    const tblBody = dataTbl.getDataBodyRange();

    // Load values and formulas from ranges. Load values of table body
    namesRng.load("values,formulas");
    tagsRng.load("values");
    attNamesRange.load("values");
    tblBody.load("values,text,formulas");
    const propertiesToGet = tblBody.getRowProperties({
      address: true,
      rowHidden: true,
      rowIndex: true,
    });

    await context.sync();

    // The values in the storage columns
    const storageColValuesAndIndexes = await getStorageColumnValues(
      context,
      sheet,
      tblBody.formulas.length
    );

    // Find the last row of table
    lastTableRow = labelsRow + tblBody.formulas.length;

    // Get the tags of all attributes
    const allTags = getAllTags(tagsRng, attNamesRange);

    // This object includes which rows are hidden or visible.
    // They key of this object is the row #. The value is either false or true.
    // True represents the row is hidden
    const rowVisibilities = {};
    for (const p of propertiesToGet.value) {
      if (p.rowIndex) {
        rowVisibilities[p.rowIndex - firstDataRow] = p.rowHidden;
      }
    }

    const nameValues = namesRng.values[0]; // The values in the names row
    const nameFormulas = namesRng.formulas[0]; // The formulas in the names row
    const tblBodyValues = tblBody.values;
    const tblBodyFormulas = tblBody.formulas;
    const tblBodyText = tblBody.text;

    // The array of cells with hyperlinks
    const cellsWithHyperlink: {
      row: number;
      attName: string; // attribute name
      cell: Excel.Range;
    }[] = [];

    // ***
    // *** Read data ***

    // If no data to add, then return blank object
    if (tblBodyFormulas.length === 0) return {};

    // If the length of names range is not matching the body, then return false as error
    if (tblBodyFormulas[0].length !== nameValues.length) return false;

    const newRows: {
      id: number;
      rowNo: number;
      attributes: object;
      timestamp: number;
      action: string;
    }[] = [];

    // If any row is hidden, this variable turns true, for example due to filtering
    anyHiddenRow = false;

    // Iterate rows of table body
    for (let r = 0; r < tblBodyFormulas.length; r++) {
      const rowFormulas = tblBodyFormulas[r]; // The formulas inside the cells of the row
      const rowText = tblBodyText[r]; // The text inside the cells of the row
      const rowValues = tblBodyValues[r]; // The values inside the cells of the row
      const c = convertLetterToNumber(firstTableCol);
      const newAttributes = {};
      const rvAction = rowValues[actionsColumn - c]; // The action in the row

      // Continue if row is hidden
      if (rowVisibilities[r] === true) {
        console.log("Hidden", r, rowValues[idColumn - c]);
        anyHiddenRow = true;
        continue;
      }

      // The address of the action cell
      const actionRngAddress =
        convertNumberToLetter(actionsColumn) + (r + firstDataRow + 1);

      // If add action for arow which already has ID, display error and continue
      if (
        actions.add === rvAction &&
        rowValues[idColumn - c].toString().length > 0
      ) {
        setCellWithAnError(
          sheet.getRange(actionRngAddress),
          actionRngAddress,
          "invalid-add"
        );
        continue;

        // Else if sync action for a row which has no ID, display error and continue
      } else if (
        actions.sync === rvAction &&
        rowValues[idColumn - c].toString().length === 0
      ) {
        setCellWithAnError(
          sheet.getRange(actionRngAddress),
          actionRngAddress,
          "invalid-sync"
        );
        continue;

        // Else if delete action for a row which has no ID, display error and continue
      } else if (
        actions.delete === rvAction &&
        rowValues[idColumn - c].toString().length === 0
      ) {
        setCellWithAnError(
          sheet.getRange(actionRngAddress),
          actionRngAddress,
          "invalid-delete"
        );
        continue;

        // If clear action for a row which already has ID, display error and continue
      } else if (
        actions.clear === rvAction &&
        rowValues[idColumn - c].toString().length > 0
      ) {
        setCellWithAnError(
          sheet.getRange(actionRngAddress),
          actionRngAddress,
          "invalid-clear"
        );
        continue;
      }

      // Iterate each attribute
      for (let i = 0; i < nameValues.length; i++) {
        let val; // The value to be inserted into the attribute of the curret row

        // If the name is not an attribute name, goto the next loop.
        // Some columns of table are not attribute columns such as ID and actions column
        if (!attNamesRange.values[0].includes(nameValues[i])) continue;

        const tags = allTags[nameValues[i]]; // Get the tags of the current attribute

        // Get the value of the current attribute/cell. Unless the attribute has
        // the save_as_text tag, then get the text of the cell
        let cellContent = rowValues[i];
        if (tags.includes(tagsIdn.saveAsText)) cellContent = rowText[i];

        // If the attribute is linked to a storage column, and its name starts with an equal sign (it's a formula) ,
        // then take the values from its storage column
        if (
          tags.includes(tagsIdn.linkedToStrgCol) &&
          nameFormulas[i].charAt(0) === "="
        ) {
          val = storageColValuesAndIndexes[nameValues[i]].values[r];

          // If the attribute has the editOnAddin tag, i.e. its value is edited on the addin
          // And the cell content has a prefix
        } else if (
          tags.includes(tagsIdn.editOnAddin) &&
          cellContent.substring(0, editOnAddinCellPrefix.length) ===
            editOnAddinCellPrefix
        ) {
          val = cellContent.substring(editOnAddinCellPrefix.length); // Remove the prefix

          // If the attribute column includes the items tag, extract ID from the last parentheses in the value
        } else if (
          tags.includes(tagsIdn.itemsWithId) ||
          tags.includes(tagsIdn.dependableItemsWithId)
        ) {
          val = extractIdFromLastParentheses(cellContent);

          // If the cell includes URL, load its hyperlink
        } else if (tags.includes(tagsIdn.hyperlink)) {
          // Insert the hyperlink to an object so later (at line #VDTRE) the hyperlinks are added to the table
          cellsWithHyperlink.push({
            row: r,
            attName: nameValues[i],
            cell: sheet.getCell(r + firstDataRow, c + i),
          });
          // Load the hyperlink of the cell with a hyperlink
          cellsWithHyperlink[cellsWithHyperlink.length - 1].cell.load(
            "hyperlink"
          );
          // By default, the hyperlink in the current cell has no address. It's just a textToDisplay
          // At line #VDTRE, hyperlinks are added if any valid hyperlink exists for this cell
          // The ref number of this line "line #QVUED"
          val = JSON.stringify({
            textToDisplay: cellContent,
            screenTip: "",
            address: "",
          });

          // If the cell includes a formula, then get the formula
        } else if (tags.includes(tagsIdn.formula)) {
          val = rowFormulas[i];
          if (tags.includes(tagsIdn.saveAsText)) val = rowText[i];
          else if (tags.includes(tagsIdn.saveAsValue)) val = rowValues[i];

          // If the attribute includes hours but it should be converted to mins
        } else if (tags.includes(tagsIdn.convertMinsToHrs)) {
          val = cellContent * 24 * 60;
          // If boolean attribute, then convert Yes/No to 0/1
        } else if (tags.includes(tagsIdn.booleanYesNo)) {
          if (cellContent.toLowerCase() == "yes") val = 1;
          else if (cellContent.toLowerCase() == "no") val = 0;
          else val = "";
          // Otherwise,
        } else {
          val = cellContent;
        }

        // If the value belongs to a checkbox attribute column, then convert the val to an array
        // This needs to be tested !!!
        val = convertStringToArrayForCheckboxes(tags, val);
        newAttributes[nameValues[i]] = val;
      }

      // Insert the values of the current row into an ibject
      newRows.push({
        id: Number(rowValues[idColumn - c]),
        rowNo: r,
        attributes: newAttributes,
        timestamp: Number(rowValues[timestampColumn - c]),
        action: rvAction,
      });
    }

    // Iterate the hyperlinks and add them to the newRows object (line #VDTRE)
    await context.sync(); // Sync to get the hyperlinks
    for (const hyperlink of cellsWithHyperlink) {
      // If no hyperlink, then do not insert the hyperlink into the newRows and continue.
      // The default value is already inserted at line #QVUED
      if (hyperlink.cell.hyperlink === null) continue;
      const json = JSON.stringify(hyperlink.cell.hyperlink);
      for (let i = 0; i < newRows.length; i++) {
        if (newRows[i].rowNo == hyperlink.row) {
          newRows[i].attributes[hyperlink.attName] = json;
          break;
        }
      }
    }
    return newRows;
  });
};

// When actions are applied on rows, this function is called which does the following,
//	+ Delete any requested row
//	+ Display checkmark for any row which is successfully synced
//	+ Display checkmark and ID for any row which is successfully added
//	+ Clear any requested row
export const afterSync = async (results, currentMeta) => {
  // Sort the results by value.row.rowNo largest to smallest.
  results.sort(
    (a, b) =>
      (b.value?.row?.rowNo || b.reason?.row?.rowNo) -
      (a.value?.row?.rowNo || a.reason?.row?.rowNo)
  );
  await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const tbl = sheet.tables.getItemAt(0);
    const tblBody = tbl.getDataBodyRange();
    const namesRange = sheet.getRange(attrNamesRngName); // The range containing attribute names
    const tagsRng = sheet.getRange(tagsRngName); // The range containing attribute tags
    const cellsWithErrorRng = sheet.getRange(cellsWithErrorRngName); // The range including the list of cells with errors
    setGlobalDisableEvents(true); // Disable events
    namesRange.load("values");
    tagsRng.load("values");
    tblBody.load("values");
    cellsWithErrorRng.load("values");

    // Get the properties of cells in the names row
    const prop: {
      address: boolean;
    } = { address: true };
    const nameCellProps = namesRange.getCellProperties(prop);

    await unprotectSheet(context, sheet); // Unprotect sheet - This calls context.sync()

    // Put the valus of cellsWithErrorRng into a one-dimensional array
    let cellsWithError: string[] = [];
    for (const address of cellsWithErrorRng.values[0][0].split(",")) {
      if (address.length > 0) cellsWithError.push(address);
    }

    // Get the values in the storage columns
    const storageColValuesAndIndexes = await getStorageColumnValues(
      context,
      sheet,
      tblBody.values.length
    ); // This calls context.sync()

    // Get the column letter of storage columns
    const storageColLetters = {};
    for (const attName in storageColValuesAndIndexes) {
      storageColLetters[attName] =
        storageColValuesAndIndexes[attName].columnLetter;
    }

    const names = namesRange.values[0]; // Put the values in the names range into an array
    let shRowNo; // The row number relative to the sheet

    // Iterate results returned by the API
    for (const result of results) {
      const moreCellsWithError: {
        msg: string; // Error message
        address: string; // The cell address with error
        cell: Excel.Range; // The cell with error
      }[] = [];
      // If the row is synced successfully, then update the sheet
      if (result.status === "fulfilled") {
        const rowNo = result.value.row.rowNo; // The row number relative to table body
        shRowNo = firstDataRow + rowNo + 1; // The row number relative to the sheet
        const action = result.value.row.action; // The action applied on the row, such as delete and sync

        // Perform a series of functions based on each action
        switch (action) {
          case actions.delete:
          case actions.clear:
            // Delete a row if there is no hidden or filtered row
            // Excel is unable to delete rows of a filtered table
            if (!anyHiddenRow) {
              deleteTableRow(
                context,
                sheet,
                tbl,
                rowNo,
                storageColValuesAndIndexes
              );
            }
            // Else display an error because a row cannot be deleted in a filtered table
            else {
              // Apply clearCfAndDp on the actions column
              const actionCell = sheet.getCell(shRowNo - 1, actionsColumn);
              clearCfAndDp(actionCell);
              // Add the error message in the action column
              moreCellsWithError.push({
                cell: actionCell,
                address:
                  "$" +
                  convertNumberToLetter(actionsColumn).toUpperCase() +
                  "$" +
                  shRowNo,
                msg: "This record is deleted from the database but it cannot be deleted from this sheet because this table is filtered. Next time when you reload the resource, the record won't be listed.",
              });
            }
            break;
          case actions.add:
          case actions.sync:
            // Clear the error-related formatting of cells with error in the current row
            cellsWithError = manageCellWithError(
              sheet,
              [shRowNo],
              [],
              cellsWithError
            );
            // Set the data in the table row
            setTableData(
              context,
              sheet,
              tbl,
              rowNo,
              [result.value.data],
              namesRange.values[0],
              getAllTags(tagsRng, namesRange),
              storageColLetters,
              currentMeta
            );
            // Apply clearCfAndDp on the actions column. i.e. clear the error-related formatting of the action column in the current row
            clearCfAndDp(sheet.getCell(shRowNo - 1, actionsColumn));
            break;
          default:
            console.log(`Action ${action} is not found`);
        }
        // If the row is not synced, then highlight the cells with errors
      } else if (result.status === "rejected") {
        const rowNo = result.reason.row.rowNo; // The row number relative to table body
        shRowNo = firstDataRow + rowNo + 1; // The row number relative to the sheet
        const errors = result.reason.data.errors; // List of errors
        // Apply clearCfAndDp on the actions column. i.e. clear the error-related formatting of the action column in the current row
        const actionCell = sheet.getCell(shRowNo - 1, actionsColumn);
        clearCfAndDp(actionCell);
        // Add the error message in the action column
        moreCellsWithError.push({
          cell: actionCell,
          address:
            "$" +
            convertNumberToLetter(actionsColumn).toUpperCase() +
            "$" +
            shRowNo,
          msg: result.reason.data.message,
        });
        // Iterate attributes with an error in the current errors
        // And add the error message and its cell address to an object
        for (const attWithError in errors) {
          // Find the column which includes the attribute
          for (let i = 0; i < names.length; i++) {
            if (names[i] == attWithError) {
              // Extract the column letter from the address
              const column = nameCellProps.value[0][i]?.address
                ?.split("!")[1]
                ?.replace(/[^a-z]/gi, "");
              const rngAddress = "$" + column + "$" + shRowNo;
              const cell = sheet.getRange(rngAddress);
              moreCellsWithError.push({
                cell: cell,
                address: rngAddress,
                msg: errors[attWithError][0],
              });
              break;
            }
          }
        }
      }

      // Clear the error-related formatting of existing cells with error in the current row and
      // add formatting for the cells with new error in the current row
      cellsWithError = manageCellWithError(
        sheet,
        [shRowNo],
        moreCellsWithError,
        cellsWithError
      );
    }

    // Save the list of cells with error on the sheet
    cellsWithErrorRng.values = [[cellsWithError.join(",")]];

    //protectSheet(context, sheet); // Protect sheet
    await context.sync();
    setGlobalDisableEvents(false); // Enable events
  });
};

// Write (load) data into the current sheet
// Inputs:
//	+ data: The data to be written on the sheet
//	+ currentMeta: The meta of current resource
//	+ currentResource: The name of the current resource
export const writeArrayToSheet = async (data, currentMeta, currentResource) => {
  return await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const sheets = context.workbook.worksheets;

    setGlobalDisableEvents(true); // Disable events
    availableCol = firstAvailableCol;

    // Load sheet info
    sheet.load("protection/protected");
    sheet.load("name");
    sheets.load("items/name");

    // Load the name of all worksheets
    const namesOnSheet = sheet.names;
    const namesWithWbScope = context.workbook.names;
    namesOnSheet.load();
    namesWithWbScope.load();

    // Unprotect sheet
    await unprotectSheet(context, sheet); // This calls context.sync()

    // Clear all cells of the sheet
    sheet.getRange().clear();

    // Unhide rows and columns
    sheet.getRange("A:XFC").columnHidden = false; // sheet.getRange().columnHidden raises error so use "A:XFC" instead (excluding the last column)
    sheet.getRange("1:10").rowHidden = false;

    // ***
    // *** Delete all names in the sheet ***
    for (let i = 0; i < namesOnSheet.items.length; i++) {
      const item = namesOnSheet.items[i];
      if (item.type === "Range" || item.type === "Error") {
        sheet.names.getItem(item.name).delete();
      }
    }

    // ***
    // *** Delete all names with workbook scope except the ones refering to the Temp~Storage sheet
    for (let i = 0; i < namesWithWbScope.items.length; i++) {
      const item = namesWithWbScope.items[i];
      if (
        (item.type === "Range" || item.type === "Error") &&
        !item.value.startsWith(tempStorageSheetName)
      ) {
        context.workbook.names.getItem(item.name).delete();
      }
    }

    await context.sync();

    // Rename sheet
    const sheetName = renameSheet(context, sheets, sheet, currentResource);

    // Add the sheet name to shData and set wbData
    shData.name = sheetName;

    // ***
    // *** Get attributes and sort them ***
    //let dataAttributes = data?.[0];
    let dataAttributes;
    if (data?.[0] !== undefined) {
      // Remove the keys which have an object value
      const data0 = {};
      for (const key in data[0]) {
        const val = data[0][key];
        if (!(typeof val === "object" && val !== null)) data0[key] = val;
      }
      dataAttributes = Object.keys(data0);
      // Remove unwanted attributes
      for (const attName of [
        "id",
        "retrieved_at",
        "created_at",
        "updated_at",
        "deleted_at",
      ]) {
        const index = dataAttributes.indexOf(attName);
        if (index > -1) {
          // only splice array when item is found
          dataAttributes.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    const attributeNames = getAttributesInOrder(dataAttributes, currentMeta);

    //const attributeLabels = attributeNames.slice(); // Temporary set the labels the same as names until the language and locale are completed

    // Get attribute labels
    const attributeLabels: string[] = [];
    for (let i = 0; i < attributeNames.length; i++) {
      attributeLabels[i] = getAttributeLabel(currentMeta, attributeNames[i]);
    }

    // Add attribute names and labels to the shData
    shData.attributeNames = attributeNames;
    // Produce an object whose keys are attribute names and the values are attribute labels
    shData.attributeLabels = twoArraysToObject(attributeNames, attributeLabels);

    // ***
    // *** Fill the row of names and tags ***
    // Insert the ID, retrieve at and actions column labels
    sheet.getCell(namesRow, idColumn).values = [[idColName]];
    sheet.getCell(namesRow, timestampColumn).values = [[retrieveAtColName]];
    sheet.getCell(namesRow, actionsColumn).values = [[actionsColName]];
    const wbTagsObj: { [key: string]: string[] } = {}; // This is used for setting the tags in the shData
    const wbColumnIndexObj: { [key: string]: number } = {}; // This is used for setting the column indexes in the shData
    // Insert names and tags for the attributes
    for (const attName of attributeNames) {
      // Insert name
      sheet.getCell(namesRow, availableCol).values = [[attName]];

      // Insert tags of each attribute to its tags row.
      // The tags are taken from the Meta.
      // If a column has multiple tags, join them with comma
      const tagsMeta = currentMeta?.attributes?.[attName]?.tags;
      let tags = "";
      if (tagsMeta !== undefined) tags = tagsMeta.join(tagSeparator);
      if (tags !== "") tags = tagSeparator + tags + tagSeparator; // Add tag separator to the beginning and end
      sheet.getCell(tagsRow, availableCol).values = [[tags]];
      wbTagsObj[attName] = tagsMeta !== undefined ? tagsMeta : [];
      wbColumnIndexObj[attName] = availableCol;
      availableCol += 1;
    }

    // Add tags and column indexes to the shData
    shData.columnIndexes = wbColumnIndexObj;
    shData.tags = wbTagsObj;

    // Get the first and last column letter of attributes
    const fatLetter = convertNumberToLetter(firstAttributeCol); // First attribute column letter
    const lcLetter = convertNumberToLetter(availableCol - 1); // Last column letter

    // ***
    // *** Name ranges ***
    // Name the attribute names range (the range including attribute names)
    sheet.names.add(
      attrNamesRngName,
      sheet.getRange(
        fatLetter + (namesRow + 1) + ":" + lcLetter + (namesRow + 1)
      )
    );
    // Name the tags range (the range including attribute tags)
    sheet.names.add(
      tagsRngName,
      sheet.getRange(fatLetter + (tagsRow + 1) + ":" + lcLetter + (tagsRow + 1))
    );
    // Name the name range (the range including attribute names and the names of other columns such as ID and action columns.)
    sheet.names.add(
      namesRngName,
      sheet.getRange(
        firstTableCol + (namesRow + 1) + ":" + lcLetter + (namesRow + 1)
      )
    );
    // Set the name of the cell including the resource name
    // Also add the resource name to the cell
    setRangeName(
      context,
      sheet,
      sheet.getRange(rscNameCellAddress),
      rscRngName,
      [[currentResource]],
      "Resource name"
    );

    // ***
    // *** Add blank table ***
    // Find the position and size of table, then add it
    const headerBegin = firstTableCol + firstDataRow; // The address of the first cell of the header
    lastTableCol = convertNumberToLetter(
      // The last table column
      // The table size is the number of attributes plus ID, Retrieve-At and Actions column
      convertLetterToNumber(firstTableCol) + attributeLabels.length + 2
    );
    const headerEnd = lastTableCol + firstDataRow; // The address of the last cell of the header
    const dataTbl = sheet.tables.add(
      headerBegin + ":" + headerEnd,
      true /*hasHeaders*/
    );
    // Add table header row
    dataTbl.getHeaderRowRange().values = [
      [idColHeader, timestampColHeader, actionsColHeader].concat(
        attributeLabels
      ),
    ];

    // Add data validation (drop downs)to the Actions column
    dataTbl.columns
      .getItem(actionsColHeader)
      .getDataBodyRange().dataValidation.rule = {
      list: {
        inCellDropDown: true,
        source:
          actions.add +
          "," +
          actions.sync +
          "," +
          actions.delete +
          "," +
          actions.clear,
      },
    };

    // ***
    // *** Create storage columns *** NOTE: Storage columns have a problem during sorting so do not use them
    // *** Create drop down or checkbox columns ***
    const wbItemsObj: { [key: string]: string } = {}; // This is used for setting the items in the shData
    const wbNoFrObj: { [key: string]: string } = {}; // This is used for setting the numberFormat in the shData
    for (const attName of attributeNames) {
      const attColNo = shData.columnIndexes[attName]; // The column index of the current attribute
      const tags = shData.tags[attName]; // The tags of the current attribute
      // If the attribute is linked to a storage column, create the storage column and insert the header
      if (tags.includes(tagsIdn.linkedToStrgCol)) {
        createStorageColumnHeader(context, sheet, dataTbl, attName, attColNo);
      }

      // itemsObj is the items of the current attribute defined in the meta
      // Items are used to define the items of dropdowns or checkboxes
      const itemsObj = currentMeta?.attributes?.[attName]?.items;
      // itemsFromObj is a reference to another attribute which has the items of the current attribute
      const itemsFromObj = currentMeta?.attributes?.[attName]?.items_from;
      // If the current attribute has items (for example dropdown or checkbox items), add them
      if (itemsObj !== undefined || itemsFromObj !== undefined) {
        const items = {};

        // If creating a new column to store the items, or use an existing column
        const dtVlSrc = createOrUseDropdownColumn(
          context,
          sheet,
          attName,
          items,
          itemsObj,
          itemsFromObj,
          tags,
          "",
          currentMeta
        );
        /*
        const useExistingItems =
          currentMeta?.attributes?.[attName]?.items_depend_on
            ?.use_existing_items;
        // If using existing items in an existing column, then get the source of that column.
        // The source will be used
        if (
          tags.includes(tagsIdn.dependableItemsWithId) &&
          useExistingItems === true
        ) {
          const rngNamePrefix =
            currentMeta?.attributes?.[attName]?.items_depend_on
              ?.spreadsheet_range_name_prefix || "";
          dtVlSrc = produceFormulaOfDependableItems(
            attName,
            rngNamePrefix,
            currentMeta
          );
          // If creating a new column to store the items
        } else {
          dtVlSrc = createOrUseDropdownColumn(
            context,
            sheet,
            attName,
            items,
            itemsObj,
            itemsFromObj,
            tags,
            currentMeta
          );
        }
		*/

        // If the attribute is linked to a storage column,
        // insert the items as a data validation in the header cell of the storage column
        const firstTblCol = convertLetterToNumber(firstTableCol);
        if (tags.includes(tagsIdn.linkedToStrgCol)) {
          sheet.getCell(
            storageColHeaderRow,
            shData.storageColumnIndex[attName]
          ).dataValidation.rule = {
            list: {
              inCellDropDown: true,
              source: "=" + dtVlSrc,
            },
          };
          // For other columns, insert the items as a data validation in the column
        } else {
          dataTbl.columns
            .getItemAt(attColNo - firstTblCol)
            .getDataBodyRange().dataValidation.rule = {
            list: {
              inCellDropDown: true,
              source: "=" + dtVlSrc,
            },
          };
        }
      }
    }

    // Add the items and numberFormat to shData
    shData.items = wbItemsObj;
    shData.numberFormat = wbNoFrObj;

    // If no data to insert (table is blank), pre-populate the first row
    if (data.length === 0) data = prePopulateRow(attributeNames, currentMeta);

    // ***
    // *** Insert data to the table ***
    const insertedData = setTableData(
      context,
      sheet,
      dataTbl,
      -1,
      data,
      attributeNames,
      shData.tags,
      shData.storageColumnIndex,
      currentMeta
    );

    // Add data to shData
    //shData.data = wbTblData;
    shData.data = insertedData;

    // If no data to insert (table is blank), pre-populate the first row
    //prePopulateRow(sheet, firstDataRow, currentMeta);

    // Calculate the last row of table
    //lastTableRow = firstDataRow + tblRows.length - 1;
    lastTableRow = firstDataRow + insertedData.length;

    // ***
    // *** Number format and other formatting ***
    for (const attName of attributeNames) {
      const attColNo = shData.columnIndexes[attName];
      const tblColNo = attColNo - convertLetterToNumber(firstTableCol);
      const tags = shData.tags[attName];
      // Set the format of attributes column
      const numberFormat = currentMeta?.attributes?.[attName]?.number_format;
      if (numberFormat !== undefined) {
        dataTbl.columns.getItemAt(tblColNo).getDataBodyRange().numberFormat =
          Array(Math.max(lastTableRow - firstDataRow, 1)).fill([numberFormat]);
        wbNoFrObj[attName] = numberFormat;
        // Also change the number format of the entire sheet column
        const c = convertNumberToLetter(attColNo);
        sheet.getRange(c + ":" + c).numberFormat = <any>numberFormat;
      }
      // Hide a column, if its visibility is hidden
      if (tags.includes(tagsIdn.hidden) && hideBackendRanges)
        sheet.getCell(labelsRow, attColNo).columnHidden = true;
      // Wrap text of the column, if has the specific tag
      if (tags.includes(tagsIdn.wrapTxt))
        //sheet.getCell(labelsRow, attColNo).alignment = { wrapText: true };
        sheet.getCell(labelsRow, attColNo).getEntireColumn().format.wrapText =
          false;
    }

    // ***
    // *** Change the bg color of required and read-only attributes ***
    const mandatoryAttributes = currentMeta?.required_attributes;
    if (mandatoryAttributes !== undefined) {
      for (let i = 0; i < attributeNames.length; i++) {
        const attName = attributeNames[i];
        const tags = shData.tags[attName];
        // Identify which cells are read-only
        let isReadOnly = false;
        if (tags.includes(tagsIdn.readOnly)) isReadOnly = true; // If the attribute has a read-only tag
        if (tags.includes(tagsIdn.formula)) isReadOnly = true; // If the attribute has a formula
        if (attName.includes(".")) isReadOnly = true; // If the attribute is a related attribute in another resource, for example, "parentResource.name". Then the attribute cannot be edited and is only for display purposes
        // Change the bg color of the header cell of the attribute column
        if (mandatoryAttributes.includes(attributeNames[i]))
          sheet.getCell(labelsRow, firstAttributeCol + i).format.fill.color =
            "#fd5e53";
        else if (isReadOnly)
          sheet.getCell(labelsRow, firstAttributeCol + i).format.fill.color =
            "#ADD8E6";
      }
    }

    availableCol += 1; // Increment the available column

    // ***
    // *** Cells with error ***
    // Set name for the header of the column which include the list of cells with errors which occured after syncing with the API
    setRangeName(
      context,
      sheet,
      sheet.getRange(convertNumberToLetter(availableCol) + labelsRow),
      cellsWithErrorHeaderRngName,
      [["Cells with error"]],
      "The list of cells with errors which occurred after syncing with the API."
    );
    // Name the range which includes the list of cells with error
    setRangeName(
      context,
      sheet,
      sheet.getRange(convertNumberToLetter(availableCol) + firstDataRow),
      cellsWithErrorRngName,
      [[""]],
      "The list of cells with errors which occurred after syncing with the API."
    );
    // Hide the column of cells with error if necessary
    if (hideBackendRanges)
      sheet.getCell(labelsRow, availableCol).columnHidden = true;

    availableCol += 1; // Increment the available column

    // Hide range if necessary
    if (hideBackendRanges) hideRanges(context, sheet);

    // Select the first data cell
    //sheet.getCell(firstDataRow, firstAttributeCol).select << Not tested

    // ***
    // *** Lock and protect ***
    lockCells(context, sheet);
    //protectSheet(context, sheet);

    await context.sync();
    setGlobalDisableEvents(false); // Do not disable events
  });
};

// ***
// *** Get data ***
// ***

// Get the tags of all given attribute
// Inputs:
//	+ tagsRange: The range of the sheet containing tags
//	+ namesRange: The range of the sheet containing names
// Pre-req:
//	+ tagsRange and namesRange should previously be loaded with their values
export const getAllTags = (tagsRange, namesRange) => {
  const tagValues = tagsRange.values[0]; // Only the data of the first row is needed
  const names = namesRange.values[0]; // Only the data of the first row is needed
  const re = {};
  for (let i = 0; i < names.length; i++) {
    if (tagValues[i].length > 0)
      re[names[i]] = tagValues[i].split(tagSeparator);
    else re[names[i]] = [];
  }
  return re;
};

// Get attributes and sort them
// The order of attributes are defined in the attributes_order of the meta
// Inputs:
//	+ attributeNames: Attribute names which are not necessarily in order. The attribute names
//					  are extracted from the data variable, which is an input parameter of the writeArrayToSheet().
//					  However, data might be null if no instances exists for the resource of the current sheet.
//					  If the data is null, then the attributeNames is also null. In that case use return the
//					  attributes_order of the meta as is.
export const getAttributesInOrder = (attributeNames, currentMeta) => {
  const attrOrders = currentMeta?.attributes_order; // The order of attributes in the meta
  let re: string[] = []; // By default return blank list of attribute names
  // Find the attributes in the meta which
  //	+ have an Excel formulas,
  //	+ have the "display" tag
  //	+ have the "related_resource" tags
  //	+ are attributes from related resources like parent_resource.name. The name of this type of attribute have a "."
  // These attributes will be shown on the table
  const toDisplayAttributes: string[] = [];
  for (const attName in currentMeta.attributes) {
    if (
      currentMeta.attributes[attName]?.excel_formula !== undefined ||
      currentMeta?.attributes?.[attName]?.tags?.includes(tagsIdn.display) ||
      currentMeta?.attributes?.[attName]?.tags?.includes(
        tagsIdn.relatedResource
      ) ||
      attName.includes(".")
    )
      toDisplayAttributes.push(attName);
  }
  // If no attributeNames, then retun attrOrders as the list of attribute names
  if (
    attrOrders &&
    (attributeNames === undefined || attributeNames?.length === 0)
  ) {
    re = attrOrders;
  } else if (attrOrders) {
    // If the 'attributes_order' does not include all attributes, sort the
    // existing attributes in the 'attributes_order' and leave the rest as is.
    // If the 'attributes_order' has invalid attributes, ignore it.
    const intersection = attrOrders.filter(
      (value) =>
        attributeNames.includes(value) || toDisplayAttributes.includes(value)
    );
    const differences = attributeNames.filter(
      (value) => !attrOrders.includes(value)
    );
    re = intersection.concat(differences);
  }
  // Remove the veryHidden attributes
  if (re.length > 0 && currentMeta?.attributes instanceof Array) {
    const attributes = currentMeta.attributes;
    for (const attribute in attributes) {
      if (attributes[attribute]?.properties?.excel?.hidden === "veryHidden") {
        const index = re.indexOf(attribute);
        if (index > -1) {
          re.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
  }
  return re;
};

// Extract ID from the value of an attributes which has the tagsIdn.itemsWithId tag.
export const extractIdFromLastParentheses = (val) => {
  if (!val.includes("(")) return ""; // Return null if the value does not have parentheses
  if (val.slice(-1) != ")") return ""; // Return null if the value does not end with ")"
  const iStart = val.lastIndexOf("(");
  const iEnd = val.lastIndexOf(")");
  if (iEnd <= iStart + 1) return ""; // Return null if the value has invalid type of parentheses
  const id = val.substring(iStart + 1, iEnd);
  if (/^\d+$/.test(id))
    // ID should consist of only digits
    return id;
  return ""; // Return null if unsuccessful to extract ID
};

// Get the instance ID in the row of the selected range
export const getIdOfSelectedRow = async () => {
  return await Excel.run(async (context) => {
    const range = context.workbook.getSelectedRange();
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const tbl = sheet.tables.getItemAt(0);
    const bdy = tbl.getDataBodyRange();
    range.load("rowIndex");
    bdy.load("values");
    await context.sync();
    const i = range.rowIndex - firstDataRow;
    const c = idColumn - convertLetterToNumber(firstTableCol);
    return bdy.values?.[i]?.[c];
  });
};

// Get the value, row and column indexes, and data validation list of the given table cell
// If the cell belongs to an attribute column, which is linked to a storage column,
// 		grab the values from the related storage column
// Inputs:
//	+ rng: The cell to get its value (Req: The address, values, columnIndex, rowIndex and data validation of the rng should be already loaded).
//	+ attNamesRange: The range containing attribute tags. (Req: The values of this range should be already loaded)
//	+ attNamesRange: The range containing attribute names. (Req: The values of this range should be already loaded)
//	+ attributeName: The attribute name in the column of the cell.
//
// Return an object which has,
//	+ value: The cell value, or the value in the related cell in the related storage column
//	+ columnIndex: The column index of the cell or the related cell in the related storage column
//	+ rowIndex: The same as abve but for row index
//	+ dataValidationList: The list in the data validation like dropdown list
//
// Pre-req:
//	+ Refer to the requirement parameters explained above
export const getTableCellValues = async (
  context,
  sheet,
  rng,
  tagsRng,
  attNamesRange,
  attributeName
) => {
  const c = rng.columnIndex;
  const r = rng.rowIndex;

  const allTags = getAllTags(tagsRng, attNamesRange); // Get the tags of all attributes
  const tags = allTags[attributeName];

  // If the attribute column is linked to a storage column, then find the value in the related storage column
  // Otherwise, return the range's value
  let rngToRead = rng;
  //const dtVlRngAddress = rng.address.split("!")[1]; // The address of range to get the items in its data validation
  let dtVlRng = rng;
  // Get the column number of the range relative to the table
  const columnNo: number =
    convertLetterToNumber(rng.address.split("!")[1].replace(/[^a-z]/gi, "")) -
    firstAttributeCol;
  let val;
  if (tags.includes(tagsIdn.linkedToStrgCol)) {
    //console.log("formula:", columnNo, attNamesRange);
    const stCol = getStorageColumnPos(attNamesRange.formulas[0][columnNo]);
    const stRng = sheet.getRange(stCol + (r + 1));
    // The items of data validation items for columns linked to storage columns are defined in the header of the storage column
    const stHeaderRng = sheet.getRange(stCol + (storageColHeaderRow + 1));
    stRng.load("address,values,columnIndex,rowIndex");
    stHeaderRng.load("address,dataValidation/rule/list/source");
    await context.sync();
    rngToRead = stRng;
    dtVlRng = stHeaderRng;
  }

  val = rngToRead.values[0][0];

  // If the attribute has the editOnAddin tag, i.e. its value is edited on the addin
  // And the cell content has a prefix
  if (
    tags.includes(tagsIdn.editOnAddin) &&
    val.substring(0, editOnAddinCellPrefix.length) === editOnAddinCellPrefix
  ) {
    val = val.substring(editOnAddinCellPrefix.length); // Remove the prefix
  }

  // Convert the string value of checkboxes to array
  val = convertStringToArrayForCheckboxes(tags, val);

  // Get the list in the data validation
  // Convert the list array to an object
  const listArr = await getDtVlList(dtVlRng);
  const listObj = {};
  let key = 0;
  if (listArr instanceof Array) {
    for (const l of listArr) {
      listObj[l] = l;
      key += 1;
    }
  }

  return {
    value: val,
    columnIndex: rngToRead.columnIndex,
    rowIndex: rngToRead.rowIndex,
    dataValidationList: listObj,
  };
};

// Similar to getTableCellValues() but the return value excludes data validation items
export const getTableCellValues2 = async (
  context,
  sheet,
  rng,
  tagsRng,
  attNamesRange,
  attributeName
) => {
  const c = rng.columnIndex;
  const r = rng.rowIndex;
  const allTags = getAllTags(tagsRng, attNamesRange); // Get the tags of all attributes
  const tags = allTags[attributeName];

  // If the attribute column is linked to a storage column, then find the value in the related storage column
  // Otherwise, return the range's value
  let rngToRead = rng;

  // Get the column number of the range relative to the table
  const columnNo: number =
    convertLetterToNumber(rng.address.split("!")[1].replace(/[^a-z]/gi, "")) -
    firstAttributeCol;
  let val;
  if (tags.includes(tagsIdn.linkedToStrgCol)) {
    const stCol = getStorageColumnPos(attNamesRange.formulas[0][columnNo]);
    const stRng = sheet.getRange(stCol + (r + 1));
    // The items of data validation items for columns linked to storage columns are defined in the header of the storage column
    const stHeaderRng = sheet.getRange(stCol + (storageColHeaderRow + 1));
    stRng.load("address,values,columnIndex,rowIndex");
    stHeaderRng.load("address,dataValidation/rule/list/source");
    await context.sync();
    rngToRead = stRng;
  }

  val = rngToRead.values[0][0];

  // If the attribute has the editOnAddin tag, i.e. its value is edited on the addin
  // And the cell content has a prefix
  if (
    tags.includes(tagsIdn.editOnAddin) &&
    val.substring(0, editOnAddinCellPrefix.length) === editOnAddinCellPrefix
  ) {
    val = val.substring(editOnAddinCellPrefix.length); // Remove the prefix
  }

  // Convert the string value of checkboxes to array
  val = convertStringToArrayForCheckboxes(tags, val);

  return {
    value: val,
    columnIndex: rngToRead.columnIndex,
    rowIndex: rngToRead.rowIndex,
    //dataValidationList: listObj,
  };
};

// Convert string values to array for checkboxes
// Inputs:
//	+ tags: The tags in the attribute column of the cell
//	+ val: The value to set its format
const convertStringToArrayForCheckboxes = (tags, val) => {
  if (tags.includes(inputTypeTagPrefix + inputTypes.checkbox)) {
    if (val.length == 0) return [];
    return JSON.parse(val);
  }
  return val; // Return value as is if no formatting is needed
};

// Format the value to be saved in a cell
// For example, stringify arrays
//	+ Convert arrays to string for checkboxes
// Inputs:
//	+ tags: The tags in the attribute column of the cell
//	+ val: The value to set its format
const formatValueForCell = (val) => {
  if (val instanceof Array) return JSON.stringify(val); // If the value is array, stringify it
  return val; // Return value as is if no formatting is needed
};

// Convert string values to array for checkboxes
// Not used - to be deleted
const convertCheckboxValueToArray = (val) => {
  if (val.length == 0) return [];
  return JSON.parse(val);
};

// Get the column letter/position of the storage column from the given formula
// 		in an attribute name cell
// Return false if the cell of the given attribute name does not have a formula.
export const getStorageColumnPos = (attributeNameCellFormula) => {
  try {
    const f = attributeNameCellFormula; //namesRng.formulas[0][0];
    if (f.length > 0 && f.charAt(0) !== "=") return false;
    return f.replace(/[^a-z]/gi, ""); // Extract alphabetical characters
  } catch {
    return false;
  }
};

// Get the label of the given attribute
const getAttributeLabel = (currentMeta, attributeName) => {
  let attributeLabel = attributeName; // Temporary set the labels the same as names until the language and locale are completed
  // Put asterisk for mandatory labels
  const mandatoryAttributes = currentMeta?.required_attributes;
  if (mandatoryAttributes !== undefined) {
    if (mandatoryAttributes.includes(attributeName))
      attributeLabel = attributeLabel + "*";
  }
  return attributeLabel;
};

// ***
// *** Set/Prepare data ***
// ***

// Set the values in the entire table of a row of the given table
// Inputs:
//	+ sheet: The sheet where the table and cell resides
//	+ tbl: The table where the cell resides
//	+ setAtRowNo: The row number of the table to be set. If -1, then set the entire table
//	+ data: The data to be set in the row or table
//	+ attributeNames: The list of attribute names
//	+ allTags: The tags of all attributes
//	+ storageColumnIndexes: The index (or column letter) of storage columns
const setTableData = (
  context,
  sheet,
  tbl,
  setAtRowNo,
  data,
  attributeNames,
  allTags,
  storageColumnIndexes,
  currentMeta
) => {
  type TableRow = [number, number, string, ...any];
  const tblRows: Array<TableRow> = [];
  const wbTblData: string[][] = [];
  let rowNo = setAtRowNo > 0 ? setAtRowNo : 0;
  //let rowNoCopy = rowNo;
  const storageColValues: { [key: string]: string[][] } = {};
  const hyperlinks: {
    row: number;
    column: number;
    hyperlinkObject: Excel.RangeHyperlink;
  }[] = [];
  let dataCopy = data.slice(); // Clone the array
  // If there is no data, add a blank row to the data so that some columns like formulas get generated
  //,!!!
  if (dataCopy.length === 0) {
    const rv = {};
    rv["id"] = "";
    rv["retrieved_at"] = "";
    for (const attName of attributeNames) {
      rv[attName] = "";
    }
    dataCopy = [rv];
  }

  // Auto populate attributes
  for (const attName of attributeNames) {
    const tags = allTags[attName];
    // If the value of the current attribute is auto populated based on the value of another attribute
    if (tags.includes(tagsIdn.autoPopulateBO)) {
      // Get the other attribute which the current attribute's value depends on
      const dependeeAttribute =
        currentMeta?.attributes?.[attName]?.auto_populate_based_on_other
          ?.attribute;
      if (dependeeAttribute === undefined) continue;
      // The below attribute of the items is searched
      const searchAttribute =
        currentMeta?.attributes?.[attName]?.auto_populate_based_on_other
          ?.search_into;
      if (searchAttribute === undefined) continue;
      // The value of the current attribute is equal to the value of the below attribute of the items
      const valueOfAttribute =
        currentMeta?.attributes?.[attName]?.auto_populate_based_on_other
          ?.value_of;
      if (valueOfAttribute === undefined) continue;
      const items = currentMeta?.attributes?.[dependeeAttribute]?.items;
      if (items === undefined) continue;
      // Sammurize the items by extracting searchAttribute and valueOfAttribute
      const items2 = {};
      for (let i = 0; i < items.length; i++) {
        items2[items[i][searchAttribute]] = items[i][valueOfAttribute];
      }
      // Iterate the data and auto populate the current attribute
      for (let i = 0; i < dataCopy.length; i++) {
        dataCopy[i][attName] = items2[dataCopy[i][dependeeAttribute]];
      }
    }
  }

  // Insert the data into the table
  for (const rv of dataCopy) {
    const tblRow: TableRow = [rv.id, rv.retrieved_at, "-"];
    wbTblData[rowNo] = [];
    let colNo = 0;
    for (const attName of attributeNames) {
      const tags = allTags[attName];
      let val = get(rv, attName); // get() is a lodash function; // rv[attName];
      if (typeof val === "object" && val !== null) continue; // Goto the next loop if the value is an object
      const valCopy = val;
      // If the value belongs to a column with a dropdown whose items have ID, add ID to the value
      if (
        tags.includes(tagsIdn.itemsWithId) ||
        tags.includes(tagsIdn.dependableItemsWithId)
      ) {
        let attNameOfItems = attName;
        // If the items of this attribute column comes from multiple resources,
        // prepare the value based on the value in the dependee column
        if (
          tags.includes(tagsIdn.itemsFromMultiResources) &&
          tags.includes(tagsIdn.dependableItemsWithId)
        ) {
          // Find the attribute that attName depends on
          const dependeeAttribute =
            currentMeta.attributes[attName]?.items_depend_on?.attribute;
          // Get the value in the dependee attribute
          // The value is actually the name of resource where the items are seaded
          const rscName = rv?.[dependeeAttribute];
          // Get the attribute linked to the resource
          attNameOfItems =
            currentMeta.attributes[attName]?.items_from?.[rscName];
          if (attNameOfItems === undefined)
            console.log("Error #CVDWQ. Issue for ", attName);
        }
        val = prepareValueOfAtrributeWithItemWithId(
          attNameOfItems,
          valCopy,
          currentMeta
        );
        // If the attribute has a formula but no value,
      } else if (tags.includes(tagsIdn.formula)) {
        // Insert the formula. But if the replaceFormulaWithValue tag is present, do not insert the formula if the val is not null
        if (
          !tags.includes(tagsIdn.replaceFormulaWithValue) ||
          (tags.includes(tagsIdn.replaceFormulaWithValue) && val === null)
        )
          //val = currentMeta.attributes[attName]?.excel_formula?.formula;
          //const params = currentMeta.attributes[attName]?.excel_formula?.params;
          // If the formula is a name formula, get its actual formula
          val = getFormula(currentMeta, attName); //(currentMeta, val, params);
        // If the attribute includes hyperlinks
      } else if (tags.includes(tagsIdn.hyperlink) && isJson(valCopy)) {
        // Save the cell address and its hyperlink in an object
        //const hObj = isJson(rv[attName]) ? JSON.parse(rv[attName]) : null; // Hyperlink Object
        const hObj = JSON.parse(valCopy);
        //hyperlinks[rowNo + "_" + colNo] = hObj;
        hyperlinks.push({ row: rowNo, column: colNo, hyperlinkObject: hObj });
        // If the hyperlink object is not defined, insert the value as is into the cell with no hyperlink
        if (hObj?.["textToDisplay"] !== undefined)
          val = hObj?.["textToDisplay"];
        // For every other attribute,
        else val = valCopy;
        // If the attribute has data in minutes which should be converted to hours
      } else if (tags.includes(tagsIdn.convertMinsToHrs)) {
        val = valCopy / (60 * 24);
        // If the "related_resource" tag is present which means to display the information about the related resource for example how many related instances exists in the related resource.
      } else if (tags.includes(tagsIdn.relatedResource)) {
        const items = currentMeta.attributes[attName]?.items;
        val = "";
        if (
          items !== undefined &&
          currentMeta.attributes[attName].related_resource_info.type == "count"
        ) {
          const numberOfRelatedItems = countBy(
            items,
            (o) =>
              o[
                currentMeta.attributes[attName].related_resource_info
                  .related_attr
              ] == rv["id"]
          ).true;
          if (numberOfRelatedItems === undefined) val = "None";
          else if (numberOfRelatedItems == 1)
            val =
              "1 " +
              currentMeta.attributes[attName].related_resource_info
                .singular_noun;
          else
            val =
              numberOfRelatedItems +
              " " +
              currentMeta.attributes[attName].related_resource_info.plural_noun;
        }
        /* Not completed
		else if (items !== undefined && currentMeta.attributes[attName].related_resource_info.type == "join") {
			let s = "";
			s = s + currentMeta.attributes[attName].related_resource_info.separator + 
				items[currentMeta.attributes[attName].related_resource_info.related_attr];
		} */
        // If boolean attribute, convert 0/1 to Yes/No
      } else if (tags.includes(tagsIdn.booleanYesNo)) {
        if (val == 1) val = "Yes";
        else if (val == 0) val = "No";
        else val = "";
      }
      // If val is an array, convert it to string
      if (Array.isArray(val)) val = val.join(",");
      // If the attribute is linked to a storage column, put the value inside the storage column
      // And put a place holder instead of the value in the attribute column
      if (allTags[attName].includes(tagsIdn.linkedToStrgCol)) {
        // Initialize storageColValues if not already
        if (!(attName in storageColValues)) storageColValues[attName] = [];
        storageColValues[attName].push([val]);
        val = placeholderOfCellToStCl;
      }
      // If the attribute has the editOnAddin tag, i.e. its value is edited on the addin, add prefix to the value
      if (tags.includes(tagsIdn.editOnAddin))
        val = editOnAddinCellPrefix + (val === null ? "" : val);

      val = val === undefined ? "" : val;
      tblRow.push(val);
      wbTblData[rowNo][attName] = val;
      colNo += 1;
    }
    tblRows.push(tblRow);
    rowNo += 1;
  }
  if (tblRows.length > 0) {
    if (setAtRowNo == -1)
      tbl.rows.add(-1 /*add rows to the end of the table*/, tblRows);
    else tbl.rows.getItemAt(setAtRowNo).values = tblRows;
  }

  // Insert hyperlinks
  for (const hyperlink of hyperlinks) {
    if (!hyperlink.hyperlinkObject || !hyperlink.hyperlinkObject.address)
      continue; // If no hyperlink, then don't insert it
    const rng = sheet.getCell(
      hyperlink.row + firstDataRow,
      hyperlink.column + firstAttributeCol
    );
    rng.hyperlink = {
      textToDisplay: hyperlink.hyperlinkObject.textToDisplay,
      screenTip: hyperlink.hyperlinkObject.screenTip,
      address: hyperlink.hyperlinkObject.address,
    };
  }

  // Insert data in the storage columns
  for (const attName of attributeNames) {
    if (allTags[attName].includes(tagsIdn.linkedToStrgCol)) {
      // Get the column letter of the storage column
      const c = Number.isInteger(storageColumnIndexes[attName])
        ? convertNumberToLetter(storageColumnIndexes[attName])
        : storageColumnIndexes[attName];
      const storageColHeight =
        storageColValues?.[attName]?.length === undefined
          ? 0
          : storageColValues[attName].length;
      const rng = sheet.getRange(
        c + (firstDataRow + 1) + ":" + c + (firstDataRow + storageColHeight)
      );
      rng.values = storageColValues[attName];
    }
  }

  return wbTblData;
};

// Prepare the value of an attributes which has the tagsIdn.itemsWithId tag.
// The value consists of items separated by comma and ends with parentheses which include an ID
// Inputs:
//	+ attName: The attribute that the value belongs to
//	+ id: The ID in the value
export const prepareValueOfAtrributeWithItemWithId = (
  attName,
  id,
  currentMeta
) => {
  if (id === "" || id === null || id === undefined) return "";
  const idInParentheses = "'(" + id + ")";
  const itemsObj = currentMeta?.attributes?.[attName]?.items;
  // If items is not defined in the meta, do not continue and return the ID in the Parentheses
  if (itemsObj === undefined) return idInParentheses;
  // If the items does not have expected structure, do not continue and return the ID in the Parentheses
  if (typeof itemsObj[0] !== "object") return idInParentheses;
  const items = [];
  for (const itemObj of itemsObj) {
    if (itemObj?.id != id) continue; // Look for the itemObj which has the same ID as the given ID
    return prepareItemForDisplay(itemObj, attName, currentMeta);
  }
  return idInParentheses; // If preparing the dropdown item is unsuccessful, return the ID as is
};

// ***
// *** Set/Get sheet and addin setting/properties such as filters ***
// ***

// Store the filter, sort and pagination values of the active sheet
export const setFspValues = async (data) => {
  await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    console.log("Setting FSP", data);
    //await unprotectSheet(context, sheet);
    await setRangeNameOnActivesheet(
      context,
      sheet,
      fspNameCellAddress,
      fspRngName,
      [[data]],
      "The value of filter, sort and pagination on this sheet"
    );
  });
};

// Store the filter, sort and pagination values of the active sheet
export const getFspValues = async () => {
  return await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    console.log("Returning FSP");
    const re = await getNamedRangeValue(context, sheet, fspRngName);
    return re[0][0];
  });
};

// ***
// *** Formulas ***
// ***

// Return the requsted formula
// Inputs:
//	+ attName: The name of the attribute which includes a formula
//
// Return the requested formula. If no such requested formula is found, return formulaName. If the
// attribute has no "excel_formula" defined in the meta, then return null
//const getFormula = (currentMeta, formulaName, params) => {
const getFormula = (currentMeta, attName) => {
  const formulaName =
    currentMeta?.attributes?.[attName]?.excel_formula?.formula;
  if (formulaName === undefined) return ""; // The attribute has no formula, then reurn null
  const params = currentMeta.attributes[attName]?.excel_formula?.params;

  let f;
  switch (formulaName) {
    // Formula to extract ID from an item. The ID is in the last parantheses of the item
    case "extract_id": {
      const item =
        "[@[" + getAttributeLabel(currentMeta, params["item"]) + "]]";
      f =
        "=IFNA(MID(" +
        item +
        ",MATCH(2,1/(MID(" +
        item +
        ",SEQUENCE(LEN(" +
        item +
        ')),1)="(")) + 1,MATCH(2,1/(MID(' +
        item +
        ",SEQUENCE(LEN(" +
        item +
        ')),1)=")")) - MATCH(2,1/(MID(' +
        item +
        ",SEQUENCE(LEN(" +
        item +
        ')),1)="(")) - 1),"")';
      break;
    }
    default: {
      // Replace attribute names in the formulas with attribute labels
      // NOTE: attributes (column titles) in formulas should be enclosed with "[@[" and "]]"
      const fSplit = formulaName.split("[@[");
      let newFomula = "";
      let inLoop = false;
      for (let i = 0; i < fSplit.length; i++) {
        const fPart = fSplit[i];
        if (inLoop) {
          const attName = fPart.substring(0, fPart.indexOf("]"));
          newFomula +=
            "[@[" +
            getAttributeLabel(currentMeta, attName) +
            fPart.substring(fPart.indexOf("]"), fPart.length);
        } else {
          newFomula = fPart;
        }
        inLoop = true;
      }
      f = newFomula;
    }
  }
  return f;
};

// ***
// *** Columns outside of table ***
// ***

// Create a storage column for the given srcColumn on the active sheet, at the end of the sheet.
// And insert a label/header in the column.
// Inputs:
//	+ srcColumn: The column which is linked to the storage column
//	+ header: The header of the storage column which is usually the same as the related attribute name
export const createStorageColumnHeader = (
  context,
  sheet,
  dataTbl,
  header,
  srcColumn
) => {
  const storageCol = findFirstAvailColAfterTable(context, sheet); // The column # of the storage column

  // Insert the storage column tag in the tags row
  sheet.getCell(tagsRow, storageCol).values = [
    [tagSeparator + tagsIdn.storageColumn + tagSeparator],
  ];

  // Insert the header of the storage column
  sheet.getCell(storageColHeaderRow, storageCol).values = [[header]];

  // Change the name of the srcColumn to a formula referring to the storage column
  sheet.getCell(namesRow, srcColumn).formulas = [
    ["=" + convertNumberToLetter(storageCol) + (namesRow + 1)],
  ];

  // Hide the column if necessary
  if (hideBackendRanges) sheet.getCell(tagsRow, storageCol).columnHidden = true;

  shData.storageColumnIndex[header] = storageCol;
};

// Get the values of all storage columns
// Inputs:
//	+ storageColHeight: The number of rows of data in a storage column
//
// Return an object with the following structure:
//		[ attribute name ] . columnLetter (Which is the column letter of the storage column)
//		[ attribute name ] . values [ rowIndex ] (which is the array of values in the storage column)
export const getStorageColumnValues = async (
  context,
  sheet,
  storageColHeight
) => {
  const tag = tagSeparator + tagsIdn.storageColumn + tagSeparator;
  const foundRanges = sheet.findAll(tag, {
    completeMatch:
      false /* Match the whole cell value, not any part of the text. */,
    matchCase: false /* Make the search case-insensitive. */,
  });
  try {
    foundRanges.load("address");
    await context.sync();
  } catch (e) {
    return []; // If no storage column is found, then return
  }
  const stClRngs: { [key: string]: Excel.Range } = {}; // The range of storage columns
  // Iterate each storage column and puts its address in an array
  for (const foundRange of foundRanges.address.split(",")) {
    const c = foundRange.split("!")[1].replace(/[^a-z]/gi, ""); // Extract column letter from the address
    const r = foundRange.split("!")[1].replace(c, ""); // Row #
    if (r != tagsRow + 1) continue; // If the found range is not in the tags row, goto next found range
    const rng = sheet.getRange(
      c + "1:" + c + (firstDataRow + storageColHeight)
    );
    rng.load("values");
    stClRngs[c] = rng; // Keep the column letter of the rng, which  is storage column range, as key
  }
  await context.sync();
  const re = {};
  // After loading the values of storage columns, put their values in an arry
  for (const col in stClRngs) {
    const rng = stClRngs[col];
    const attName = rng.values[namesRow][0];
    const stClValues: string[] = [];
    for (let i = firstDataRow; i < rng.values.length; i++) {
      stClValues.push(rng.values[i][0]);
    }
    re[attName] = { columnLetter: col, values: stClValues };
  }
  return re;
};

// Create drop down column on the given sheet, or use an existing dropdown column
// The items of a dropdown is inserted into a named range
// Inputs:
//	+ attributeName: The attribute name of the column with dropdown
//	+ items: the items of the dropdown
//	+ itemsObj: The items in currentMeta.attributes.[attName].items;
//	+ tags: The tags of the current attribute
//	+ columnNo: The column number of the first column including the items. If not defined or "", then find the first available column
//	+ currentMeta: The current meta
// Return the source of the items to be used as the source of the data validation
export const createOrUseDropdownColumn = (
  context,
  sheet,
  attributeName,
  items,
  itemsObj,
  itemsFromObj,
  tags,
  columnNo,
  currentMeta
) => {
  // Check if it's necessary to create a new sets of items in a column or use existing items in an existing column
  // In the following cases, existing items in an existing column is used,
  //	+ If the attribute has the dependable_items_with_id tag. i.e. the items of the current attribute depends on those of another attribute
  //	+ If in the meta, the items_depend_on.use_existing_items is defined for the attribute

  // If using existing items in an existing column, then get the source of that column.
  // The source will be used
  const useExistingItems =
    currentMeta?.attributes?.[attributeName]?.items_depend_on
      ?.use_existing_items;
  if (
    tags.includes(tagsIdn.dependableItemsWithId) &&
    useExistingItems === true
  ) {
    const rngNamePrefix =
      currentMeta?.attributes?.[attributeName]?.items_depend_on
        ?.spreadsheet_range_name_prefix || "";
    return produceFormulaOfDependableItems(
      attributeName,
      rngNamePrefix,
      currentMeta
    );
  }

  // Get the column # of the dropdown column
  // If the column number is already defined, use it
  // otherwise, find the first available column
  let drpCol;
  if (columnNo != undefined && columnNo != "") drpCol = columnNo;
  else drpCol = findFirstAvailColAfterTable(context, sheet);

  // If values of itemsObj are not an object, i.e. itemsObj is an array of items,
  // then use the array to define items.
  // This type of items are usually used for attributes with static items like,
  // industry attribute which is a dropdown with static items such as ["Construction", "Finance", "Health"]
  if (itemsObj !== undefined && typeof itemsObj[0] !== "object") {
    items[attributeName] = itemsObj;

    // If itemsObj is an object and the attribute column has the items with ID tag,
    // then convert the object to array of items.
    // This type of items are usually used for dynamic items. Each item is identified
    // by an ID in the last parantheses in the item. Example are countries dropdown and list of clients
  } else if (
    itemsObj !== undefined &&
    tags.length > 0 &&
    (tags.includes(tagsIdn.itemsWithId) ||
      tags.includes(tagsIdn.dependableItemsWithId))
  ) {
    items = [];
    // If the items_depend_on.group_by is present for the current attribute,
    // then group the items. For example, the items may be the list of provinces
    // which are dependable on a country. Therefore, the provinces are grouped by their
    // country
    // If items_depend_on.group_by is not present, then group by a default key which is
    // handled in the prepareItemsForDisplay() function
    const groupBy =
      currentMeta?.attributes?.[attributeName]?.items_depend_on?.group_by;
    // Prepare items for display in sheets
    items = prepareItemsForDisplay(
      itemsObj,
      groupBy,
      attributeName,
      attributeName,
      currentMeta
    );

    // If items are coming from items of other attributes, then grab the items from those attributes
    // This type of items are usually used for morphable attributes like contacts which can belong
    // to both clients and leads
  } else if (itemsFromObj !== undefined) {
    for (const groupKey in itemsFromObj) {
      const otherAttrName = itemsFromObj[groupKey];
      const otherAttrItems = currentMeta.attributes?.[otherAttrName]?.items;
      // Merge the items of the other attribute to items
      Object.assign(
        items,
        prepareItemsForDisplay(
          otherAttrItems,
          undefined,
          groupKey,
          otherAttrName,
          currentMeta
        )
      );
    }
  }

  // Populate the column header
  sheet.getCell(tagsRow, drpCol).values = [
    [tagSeparator + tagsIdn.itemsColumn + tagSeparator],
  ];
  sheet.getCell(labelsRow, drpCol).values = [
    [attributeName + itemsColumnTitlePostfix],
  ];

  // Get range to-be-named and the range name
  // And insert items in the range
  const drpColLetter = convertNumberToLetter(drpCol); // Dropdown column letter
  let r = firstDataRow;
  let rngName;
  // The prefix used in naming ranges
  const rngNamePrefix =
    currentMeta?.attributes?.[attributeName]?.items_depend_on
      ?.spreadsheet_range_name_prefix || "";
  // For non dependale items, there is only one groupKey and this loop iterate only once
  for (const groupKey in items) {
    const rCopy = r;
    for (const item of items[groupKey]) {
      sheet.getCell(r, drpCol).values = [[item]];
      r += 1;
    }
    rngName = itemsRangePrefix + rngNamePrefix + groupKey + "_items";
    const rngAddress = drpColLetter + (rCopy + 1) + ":" + drpColLetter + r;
    const rng = sheet.getRange(rngAddress);
    sheet.names.add(rngName, rng);
  }
  // Hide the column if necessary
  if (hideBackendRanges) sheet.getCell(labelsRow, drpCol).columnHidden = true;
  let re;
  // If the items of the current attribute depends on another attribute
  if (tags.includes(tagsIdn.dependableItemsWithId)) {
    re = produceFormulaOfDependableItems(
      attributeName,
      rngNamePrefix,
      currentMeta
    );
  } else {
    re = rngName; //'INDIRECT("rng_' + rngName + ' & "_items")'; // For non dependable items, return the name of the range including the items
  }
  return re;
};

// The given itemsObj is an array in which each element of the array consists of pairs of an attribute and its value.
// This function join the attribute values with comma to be displayed as a single item. Then push the item to the items[key] array
// where key is the key that the set of items are grouped by.
// For example let's say the itemsObj of the provice attribute is
//	0: {id: 1, name: 'Calgary', province_id: 3, retrieved_at: 1662426617, created_at: null}
//	1: {id: 2, name: 'Vancouver', province_id: 1, retrieved_at: 1662426617, created_at: null}
// Let's say the displayable_attributes_on_items of the provice attribute in the meta is [ "short_name" ]
// And the groupBy is 'province_id'
// Then the function returns ar array with the following items
//	1: ['Vancouver (2)']
//	3: ['Calgary (1)']
//
// Inputs:
//	+ itemsObj: explained in the function description
//	+ groupBy: Group items according to the instructions in items_depend_on.group_by of the meta
//	+ defaultGrpKey: If the instructions in the meta for grouping is not available, group by this
//	+ attributeName: The attribute name of the column where the items belong to
//	+ currentMeta: The current meta of resource
//
// Return items
const prepareItemsForDisplay = (
  itemsObj,
  groupBy,
  defaultGrpKey,
  attributeName,
  currentMeta
) => {
  const items = {};
  for (const itemObj of itemsObj) {
    let groupKey = defaultGrpKey; // By default the items are grouped by defaultGrpKey
    // If the groupBy is defined
    if (groupBy !== undefined && get(itemObj, groupBy) !== undefined)
      groupKey = get(itemObj, groupBy);
    // If groupKey is null, then ignore this item
    // For example, consider the resource of provinces which are grouped by countries.
    // An unknown province may not belong to a country, therefore it's groupKey is null
    // which won't be added to the list of items
    if (groupKey === null) continue;
    // Initialize items[groupKey], if it's not already
    if (!Object.prototype.hasOwnProperty.call(items, groupKey))
      items[groupKey] = [];
    // Get the displayable item
    const item = prepareItemForDisplay(itemObj, attributeName, currentMeta);
    items[groupKey].push(item);
  }
  return items;
};

// Prepare a single item for display
// The given itemsObj consists of pairs of attributes and values.
// For example, the itemsObj of the province attribute is,
//	{id: 3, short_name: 'AB', country_id: 2, retrieved_at: 1662426053, created_at: null}
// This function join the attribute values with comma to be displayed as a single item
// and add the ID in the parentheses.
// In the example above, when the values are joined together to form an item, the generated item is,
//	3, AB, 2, 1662426053 (3)
// If the displayable_attributes_on_items is defined in the meta, only the specified values are used
// to generate the item. Let's say displayable_attributes_on_items = [ "short_name" ], then the
// generated item is,
//	AB (3)
// Inputs:
//	+ itemsObj: explained in the function description
//	+ attributeName: The attribute name of the column where the item belong to
//	+ currentMeta: The current meta of resource
//
// Return the single item
const prepareItemForDisplay = (itemObj, attributeName, currentMeta) => {
  let inLoop = false;
  let item = "";
  // To make a single dropdown item, join the values/attributes separated by comma and add the ID in the parentheses
  const displayableAttributes =
    currentMeta?.attributes?.[attributeName]?.displayable_attributes_on_items; // What attributes to display in each item
  // If no displayableAttributes exists, display all attributes
  if (displayableAttributes === undefined) {
    for (const attName in itemObj) {
      if (inLoop) item += ", ";
      item += itemObj[attName];
      inLoop = true;
    }
  } else {
    for (const attName of displayableAttributes) {
      const a = get(itemObj, attName); // get() is a lodash function
      if (a !== undefined) {
        if (inLoop) item += ", ";
        item += a;
        inLoop = true;
      }
    }
  }
  return item + " (" + itemObj.id + ")";
};

// Find the first available column after the table
// And increment the availableCol
export const findFirstAvailColAfterTable = (context, sheet) => {
  if (convertLetterToNumber(lastTableCol) + 1 == availableCol) {
    availableCol += 2; // Insert the storage column 2 columns after the data table
    setRangeName(
      // Set the range name of the first available column to insert items
      context,
      sheet,
      sheet.getRange(convertNumberToLetter(availableCol) + labelsRow),
      firstItemsColumnRngName,
      [["First column of Items"]],
      "The first column listing items"
    );
  } else availableCol += 1;
  return availableCol;
};

// Produce the formula of cells that the source of its data validation items is dependable on the
// the value of another cell
// Inputs:
//	+ attributeName: The attribute name of the column where the items belong to
//	+ rngNamePrefix: The prefix used in naming ranges
const produceFormulaOfDependableItems = (
  attributeName,
  rngNamePrefix,
  currentMeta
) => {
  // Get the attribute that the items depends on
  const attrDependsOn =
    currentMeta?.attributes?.[attributeName]?.items_depend_on?.attribute;
  // If attrDependsOn is missing in the meta, then display an error
  if (attrDependsOn === undefined)
    console.log("Error #RVDIY. Please contact your support team");
  // Define a INDIRECT() to dynamically update items in the data validation based on a selection in the
  // dependee attribute.
  // Find the parameter used inside the INDIRECT(). The default parameter is the address
  // of attrDependsOn column which is indicated by its label
  let f;
  // If attrDependsOn column is linked to storage column, then the items depend on the value in the storage column
  if (shData.tags?.[attrDependsOn]?.includes(tagsIdn.storageColumn)) {
    const c = convertNumberToLetter(shData.storageColumnIndex[attrDependsOn]);
    const r = firstDataRow + 1;
    f = c.toUpperCase() + r;
  } else {
    const c = convertNumberToLetter(shData.columnIndexes[attrDependsOn]);
    const r = firstDataRow + 1;
    f = c.toUpperCase() + r;
  }
  return (
    'INDIRECT("' +
    itemsRangePrefix +
    rngNamePrefix +
    '" & ' +
    f +
    ' & "_items")'
  );
};

// ***
// *** Add/Sync/Delete Actions ***
// ***

// Delete the given table row and its related cells in
//	+ Storage columns
// Inputs are,
//	+ tbl: The table whose row is deleted
//	+ rowNo: The row of the table to be deleted
//	+ storageColValues: The values in storage columns
export const deleteTableRow = (
  context,
  sheet,
  tbl,
  rowNo,
  storageColValues
) => {
  // Delete the row of the data table
  tbl.rows.getItemAt(rowNo).delete();

  // Update/delete the related cell in the storage columns
  for (const attName in storageColValues) {
    const stCl = storageColValues[attName];
    sheet
      .getRange(stCl.columnLetter + (firstDataRow + rowNo + 1))
      .delete(Excel.DeleteShiftDirection.up);
    /* console.log(
      "Rng deleted: ",
      stCl.columnLetter + (firstDataRow + rowNo + 1)
    ); */
  }
};

// ***
// *** Cells with error ***
// ***

// This function turns the bg color of the given cell, which has wrong action, to red.
// And add a message based on the msgType to its data validation
// Inputs:
//	+ cell: The cell to be highlighted
//	+ rngAddress: The address of the cell
//	+ msgType: The type of message or the message to be displayed when hovering the cell
export const setCellWithAnError = (cell, rngAddress, msgType) => {
  let msg = "";
  switch (msgType) {
    case "invalid-add":
      msg = "This row already exists in your database. You cannot re-add it.";
      break;
    case "invalid-sync":
      msg =
        "This row does not exist in your database. You cannot sync it. Try to add it, first.";
      break;
    case "invalid-delete":
      msg = "This row does not exist in your database. You cannot delete it.";
      break;
    case "invalid-clear":
      msg =
        "This row cannot be cleared because it already exists on your database. To delete this row from your database, select 'Delete'.";
      break;
    default:
      msg = msgType;
  }
  const conditionalFormat = cell.conditionalFormats.add(
    Excel.ConditionalFormatType.custom
  );

  // Set the bg color to red and add the msg to the input message of data validation
  conditionalFormat.custom.format.fill.color = cellWithError_bgColor;
  conditionalFormat.custom.rule.formula = '=NOT(ISBLANK("' + rngAddress + '"))';

  cell.dataValidation.prompt = {
    message: msg,
    showPrompt: true, // The default is 'false'.
    //title: "Ivalid action",
  };
};

// Find the next cell with error
// Inputs:
//	+ action which can be,
//		+ "next": selects the next cell
//		+ "previous": selects the previous cell
//		+ "clearActive": clear the active cell
//		+ "clearAll": clear all cells with error
export const selectOrClearCellWithError = async (action) => {
  await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    //let activeCellBgColor; // The BG color of the active cell

    // The range including the list of cells with errors
    const cellsWithErrorRng = sheet.getRange(cellsWithErrorRngName);
    cellsWithErrorRng.load("values");

    setGlobalDisableEvents(true); // Disable events

    // Active cell
    let activeCell = context.workbook.getActiveCell();
    let activeFill = activeCell.format.fill;
    activeCell.load("address");
    activeFill.load("color");

    await context.sync();

    // Put the valus of cellsWithErrorRng into a one dimensional array
    const cellsWithError: string[] = [];
    for (const address of cellsWithErrorRng.values[0][0].split(",")) {
      if (address.length > 0) cellsWithError.push(address);
    }

    const activeCellAddress = activeCell.address
      .replaceAll("$", "")
      .split("!")[1];

    // For the previous or next action, select the previous or next cell
    if (action === "previous" || action === "next") {
      const cells = cellsWithError.slice(); // Clone the array of cells with error
      let cellToSelect = cells[0]; // Default cell to select
      if (action === "previous") {
        cells.reverse(); // Reverse the array
        cellToSelect = cells[cells.length - 1]; // Default cell to select, if the action is previous
      }
      // Find the next or previous cell
      for (const address of cells) {
        if (compareCellAddress(address, activeCellAddress) > 0) {
          cellToSelect = address;
          break;
        }
      }
      activeCell = sheet.getRange(cellToSelect);
      activeCell.select();
      // Get the BG color of the new active cell
      activeFill = activeCell.format.fill;
      activeCell.load("address");
      activeFill.load("color");
    } else if (action === "clearActive" || action === "clearAll") {
      let newCellsWithErrorStr = "";

      // If clearing active cell, clear the formatting of the active cell if it contains an error
      // Also remove the active cell from the cellsWithError
      if (action === "clearActive") {
        const index = cellsWithError.indexOf(activeCellAddress);
        if (index > -1) {
          clearCfAndDp(activeCell);
          cellsWithError.splice(index, 1); // 2nd parameter means remove one item only
        }
        // Sort the cell addresses by row then by column
        const cells = sortCellAddressByRow(cellsWithError);
        newCellsWithErrorStr = cells.join(",");
      }

      // If clearing the formatting of all cells with error
      else if (action === "clearAll") {
        for (const address of cellsWithError) {
          clearCfAndDp(sheet.getRange(address));
        }
      }
      // Save the list of cells with error
      cellsWithErrorRng.values = [[newCellsWithErrorStr]];
    }
    await context.sync();

    // If the background color of the cell is not red, then re-apply the action
    // This situation may happen if a cell exists in the rng_cells_w_error range which does not include an error. Maybe
    // it previously inluded an error but somehow its error got cleared but it's still present in the rng_cells_w_error range
    // NOTE: there may be no way to get the bg color of a cell by conditional formatting. So this solution may not work
    /*
	activeCellBgColor = activeFill.color;
	if ( activeCellBgColor === cellWithError_bgColor ) {
		await selectOrClearCellWithError(action);
	} */

    setGlobalDisableEvents(false); // Enable events
  });
};

// Clear the conditional formatting of the given cell and the dataValidation.prompt
// This function is used to clear the error-related formatting of cells with error
export const clearCfAndDp = (cell) => {
  //const conditionalFormat = cell.conditionalFormats.clearAll();
  cell.conditionalFormats.clearAll();
  cell.dataValidation.prompt = {
    message: "",
    showPrompt: false, // The default is 'false'.
  };
};

// ***
// *** Sheet, table and range functions ***
// ***

// Lock specific cells of the given sheet
export const lockCells = (context, sheet) => {
  sheet.getRange().format.protection.locked = false;
  //sheet.getRange(firstTableCol + ":" + firstTableCol).format.protection.locked = true; // ID Column - Do not lock column ID because the table cannot be sorted
  sheet.getRange(tagsRow + 1 + ":" + (tagsRow + 1)).format.protection.locked =
    true; // Tags row
  sheet.getRange(namesRow + 1 + ":" + (namesRow + 1)).format.protection.locked =
    true; // Names row
  sheet.getRange(rscRngName).format.protection.locked = true; // Lock the range containing the resource name
  sheet.getRange(fspNameCellAddress).format.protection.locked = false; // Unlock the FSP range
};

// Hide specific ranges of the given sheet
export const hideRanges = (context, sheet) => {
  sheet.getRange(rscNameCellAddress).columnHidden = true; // Hide column A
  sheet.getCell(1, idColumn).columnHidden = true; // Hide ID column
  sheet.getCell(1, timestampColumn).columnHidden = true; // Hide the timestamp/Retrieve-At column

  sheet.getCell(tagsRow, 1).rowHidden = true; // Hide the tagsRow
  sheet.getCell(namesRow, 1).rowHidden = true; // Hide the namesRow
};

// Add X blank rows. Populate the attributes of the rows based on the data parameter
// Inputs:
//	+ x: The number of blank rows
//	+ data: The data is an array which consists of the following pair,
//		{ "attribute": ATT, "value": VAL }, where ATT is an attribute name and VAL is the value
//		to be inserted in the ATT attribute column.
export const addXBlankRows = (x, data) => {
  // To be completed
};

// Pre-polulate the new row with default values
// Inputs:
//	+ attributeNames: The array of attribute names
//	+ rowNo: The row # to pre-populate
//	+ tags: Tags of attributes_order
//	+ attributeNames: Name of attributes
//	+ columnIndexes: Column index of attributes
//	+ currentMeta: The meta of the current resource
const prePopulateRow = (attributeNames, currentMeta) => {
  const rv = {};
  rv["id"] = "";
  rv["retrieved_at"] = "";
  for (const attName of attributeNames) {
    let v;
    const defaultValue = currentMeta?.default_values?.[attName];
    //let formula = currentMeta.attributes[attName]?.excel_formula?.formula;
    //const params = currentMeta.attributes[attName]?.excel_formula?.params;
    // If the formula is a name formula, get its actual formula
    const formula = getFormula(currentMeta, attName);

    if (defaultValue !== undefined) v = defaultValue;
    else if (formula !== "") v = formula;
    rv[attName] = v;
  }
  return [rv];
};

// Rsize the data table in the activesheet
// Inputs:
//	+ lastRow: Indicates the last row of the table. If null, then cover all used rows below the table
export const resizeTable = async (row = null) => {
  /* return await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const tbl = sheet.tables.getItemAt(0);
  }); */
  // TBC
};

// ***
// *** Rich fields such as textboxes and checkboxes ***
// ***

// Get/Set the value, type and position of the selected rich cell
// A rich cell/field is like a textbox or checkbox
// This function returns an object with the following items,
//	+ type: which indicates the type of field such as "text" and "checkbox". Types are defined on `inputType`
//	+ value: The value in the field. It should be an array for checkbox type. For example,
//	+ items: { value1: "text1", value2: "text2", value3: "text3" }, // for select, checkbox and radio types only
//	+ rowIndex: The row of the cell containing the value
//	+ columnIndex: The column of the cell containing the value
// The function returns false if the cell is not editable
export const getValuesOfRichCell = async (currentMeta) => {
  return await Excel.run(async (context) => {
    const disableEvents = getGlobalDisableEvents(); // Get the status of disableEvents
    setGlobalDisableEvents(true); // Temporary disableEvents
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const activeCell = context.workbook.getActiveCell();
    const tbl = sheet.tables.getItemAt(0); // The data table
    const namesRng = sheet.getRange(attrNamesRngName);
    const tagsRng = sheet.getRange(tagsRngName);
    activeCell.load(
      "address,values,columnIndex,rowIndex,dataValidation/rule/list/source"
    );
    tagsRng.load("values");
    namesRng.load("formulas,values");

    // Check if the selected cell intersects the table. If not return error
    try {
      /* const intersectionRange = */ tbl
        .getRange()
        .getIntersection(activeCell)
        .load("address");
      await context.sync();
    } catch {
      return Promise.reject("Not intersecting table");
    }

    // Get the row and column of the selected cell
    const c = activeCell.columnIndex;
    //const r = activeCell.rowIndex;

    // Get the attribute name in the selected column
    const attNameCell = sheet.getCell(namesRow, c);
    attNameCell.load("values");

    await context.sync();
    const attName = attNameCell.values[0][0];

    const allTags = getAllTags(tagsRng, namesRng); // Get the tags of all attributes

    // Determine the type of rich cell by parsing the tags
    let inType;
    const tags = allTags[attName];
    if (tags.includes(inputTypeTagPrefix + inputTypes.checkbox)) {
      inType = inputTypes.checkbox;
    } else if (tags.includes(inputTypeTagPrefix + inputTypes.textarea)) {
      inType = inputTypes.textarea;
    } else {
      inType = inputTypes.text; // Default type
    }

    // Get the value, row, column index of the cell, or the related cell in the related storage column
    const vals = await getTableCellValues2(
      context,
      sheet,
      activeCell,
      tagsRng,
      namesRng,
      attName
    );

    /*
    console.log(
      ",,,  ",
      attName,
      inType,
      vals.value,
      vals.rowIndex,
      vals.columnIndex,
      vals.dataValidationList
    ); */

    /*
  const listArr = await getDtVlList(dtVlRng);
  const listObj = {};
  let key = 0;
  if (listArr instanceof Array) {
    for (const l of listArr) {
      listObj[l] = l;
      key += 1;
    }
  } */

    // !!! The loop below is new and is NOT TESTED
    // vals.dataValidationList is replaced with items

    // Find the items from the current meta
    const items = {};
    const metaItems = currentMeta?.attributes?.[attName]?.items;
    if (metaItems) {
      for (const mItem of metaItems) {
        const id = mItem?.id;
        let item = "";
        if (!id) console.log("Error #CVDTEW. An item does not have an ID");
        // An item should end with an ID enclosed in parentheses
        else
          item = prepareValueOfAtrributeWithItemWithId(
            attName,
            id,
            currentMeta
          );
        items[item] = item;
      }
    }

    setGlobalDisableEvents(disableEvents); // Restore the disableEvents
    return {
      attribute: attName,
      type: inType, // One of `inputType`
      tags: tags, // The attribute tags
      value: vals.value, // should be an array for checkbox type
      //items: vals.dataValidationList, //{ value1: "text1", value2: "text2", value3: "text3" }, // for select, checkbox and radio types only
      items: items, //{ value1: "text1", value2: "text2", value3: "text3" }, // for select, checkbox and radio types only
      rowIndex: vals.rowIndex,
      columnIndex: vals.columnIndex,
    };
  });
};

// As above function, but it sets the value
// Inputs: The data object which has the following items:
//	+ value: The value to set (If clearing the value, then set the value to be null)
//	+ rowIndex: The row of the cell containing the value
//	+ columnIndex: The column of the cell containing the value
export const setValueOfRichCell = async (data) => {
  return await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();

    // Get the row and column # of the cell whose value is to be set
    const c = data.columnIndex;
    const r = data.rowIndex;
    let val = formatValueForCell(data.value); // If the value is array, stringify it

    // If the attribute has the editOnAddin tag, i.e. its value is edited on the addin, add prefix to the value
    if (data.tags.includes(tagsIdn.editOnAddin))
      val = editOnAddinCellPrefix + (val === null ? "" : val);

    sheet.getCell(r, c).values = [[val]];

    const idCell = sheet.getCell(r, idColumn);
    idCell.load("values");

    await context.sync();

    // Insert "Add" or "Sync" in the actions column
    if (idCell.values[0][0])
      sheet.getCell(r, actionsColumn).values = [[actions.sync]];
    else sheet.getCell(r, actionsColumn).values = [[actions.add]];

    await context.sync();
  });
};

// ***
// *** Manage the list of cells with error ***
// ***

// Add/Remove a cell to the list of cells with error which happened after syncing with the API
// Also clear the conditional formatting of the cellAddress, for all actions
// from the list.
// Inputs:
//	+ sheet: The sheet containing the cells
//	+ rowsToClear: It's an array and is the list of rows to clear the formatting of cells with error
//	+ moreCellsWithError: It's an array of cells with error. Each object cointains the following keys,
//		+ address: the address of the cell with error
//		+ cell: the cell with error
//		+ msg: The error message
//	+ cellsWithError: The array of cell addresses with error
//
// Return the new list of cell addresses with error
const manageCellWithError = (
  sheet,
  rowsToClear,
  moreCellsWithError,
  cellsWithError
) => {
  // Remove the cell addresses and clear formatting of cells with error in the given row
  const cellsWithErrorCopy = cellsWithError.slice(); // Clone the array
  for (const row of rowsToClear) {
    for (let i = 0; i < cellsWithErrorCopy.length; i++) {
      // Remove a cell address from the cellsWithError, if its row is matching the row
      if (
        cellsWithErrorCopy[i].replace(/[^0-9]/g, "").toString() ===
        row.toString()
      ) {
        // Apply clearCfAndDp on the address to be removed
        clearCfAndDp(sheet.getRange(cellsWithErrorCopy[i]));
        // Remove the cell from the array
        const j = cellsWithError.indexOf(cellsWithErrorCopy[i]);
        if (j > -1) cellsWithError.splice(j, 1); // 2nd parameter means remove one item only
      }
    }
  }

  // Add cells addresses
  for (const cell of moreCellsWithError) {
    // Remove $ from the cell address
    const cellAddress = cell.address.replaceAll("$", "");
    // Add the cell address to the array, if it does not already exist
    if (cellsWithError.indexOf(cellAddress) === -1)
      cellsWithError.push(cellAddress);
    // Format the cell with an error
    setCellWithAnError(cell.cell, cell.address, cell.msg);
  }

  // Sort cell addresses by row then column
  return sortCellAddressByRow(cellsWithError);
};

// ***
// *** Add & Write To Sheets
// ***

// Add a new sheet and then write to the sheet
// This function is used to load the data of a related resource in a new sheet
export const addAndWriteToSheet = async (
  data,
  currentMeta,
  currentResource,
  selectedRelatedResource
) => {
  await addWorksheet();
  let relRscData = data?.[selectedRelatedResource];
  if (!Array.isArray(relRscData)) relRscData = [relRscData];
  await writeArrayToSheet(relRscData, currentMeta, selectedRelatedResource);
};

// ***
// *** Reports ***
// ***

// Genrate a report,
// Inputs:
//	+ data: The data which populates the report
//	+ meta: The meta of the resource that the report belongs to
//	+ resource: The resource that the report belongs to
//	+ template: The information of template which the report is based on
export const generateReport = (
  data,
  meta,
  resource,
  template
): Promise<void> => {
  return import("@/custom").then((custom) =>
    // Call the custom generate report function in the custom\index.ts
    custom.generateReport(data, resource, template)
  );
};

// ***
// *** Insert blank row in Sheet ***
// ***

// Insert blank row(s) into the sheet and put the default values and formulas in the new rows
export const insertBlankRow = async (currentMeta) => {
  return await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const activeCell = context.workbook.getActiveCell();
    const rng = context.workbook.getSelectedRange();
    const tbl = sheet.tables.getItemAt(0);
    const tblRange = tbl.getDataBodyRange();
    const lastTblCell = tblRange.getLastCell(); // The last cell of the table
    const headerRange = tbl.getHeaderRowRange().load("values"); // Get data from the header row.
    activeCell.load("columnIndex,rowIndex,address");
    rng.load("address,rowCount");
    //tbl.load("address");
    lastTblCell.load("columnIndex,rowIndex");
    await unprotectSheet(context, sheet); // Unprotect sheet - This calls context.sync()

    // Insert rows if the selected cell
    //	+ Is not on the heading
    //	+ Is not outside of the table (the row below the table is fine)
    if (
      activeCell.rowIndex > 0 &&
      lastTblCell.columnIndex >= activeCell.columnIndex &&
      lastTblCell.rowIndex + 1 >= activeCell.rowIndex
    ) {
      let rowNumbers = rng?.rowCount; // The number of rows to add which is the number of selected rows
      if (rowNumbers === undefined) rowNumbers = 1;
      const attributeNames = getAttributesInOrder([], currentMeta);
      const data = prePopulateRow(attributeNames, currentMeta);
    }
    //protectSheet(context, sheet); // Protect sheet
    await context.sync();
  });
};

// ***
// *** Refresh active row ***
// ***

// Refresh the row of the active cell by getting data from the database and inserting to the row
const refreshActiveRow = async (data) => {
  return await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const rng = context.workbook.getSelectedRange();
  });
};

// Get the ID of the active rows. i.e. the rows of the active range
// The ID's are returned as array. If noactive row, then a blank array is returned
export const getSelectedRowsId = async () => {
  return await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const rng = context.workbook.getSelectedRange();
    const tbl = sheet.tables.getItemAt(0);
    const tblRange = tbl.getDataBodyRange();
    rng.load(["rowCount", "rowIndex", "values"]);
    tblRange.load();
    await context.sync();
    const ids: string[] = [];
    const c = idColumn - convertLetterToNumber(firstTableCol); // The ID column
    // Iterate the rows of the active range and find the ID in each row.
    for (let row = 0; row < rng.rowCount; row++) {
      const id = tblRange.values[rng.rowIndex - firstDataRow + row][c];
      if (id.toString().length > 0) ids.push(id);
    }
    return ids;
  });
};

// ***
// *** Refresh columns including items ***
// ***

// Refresh the data of columns which include items such as dropdown items
// These columns are to the right of the table
export const refreshItemsColumns = async (currentMeta) => {
  return await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const tagsRng = sheet.getRange(tagsRngName);
    const attNamesRange = sheet.getRange(attrNamesRngName); // The range containing attribute names
    const labelRng = sheet.getRange(
      "A" + (labelsRow + 1) + ":ZZ" + (labelsRow + 1)
    );
    const firstItemsColumn = sheet.getRange(firstItemsColumnRngName);
    /*const foundItemColumn = labelRng.find("Food", {
        completeMatch: true, // Match the whole cell value.
        matchCase: true, // Don't match case.
        searchDirection: Excel.SearchDirection.forward // Start search at the beginning of the range.
    });*/
    // Load the name of all worksheets
    const namesOnSheet = sheet.names;
    tagsRng.load("values");
    attNamesRange.load("values");
    firstItemsColumn.load("columnIndex");
    namesOnSheet.load();
    await unprotectSheet(context, sheet); // Unprotect sheet - This calls context.sync()
    // Remove all range names which include items. The prefix of the range names is defined by the itemsRangePrefix constant
    for (let i = 0; i < namesOnSheet.items.length; i++) {
      const item = namesOnSheet.items[i];
      if (item.type === "Range" && item.name.startsWith(itemsRangePrefix)) {
        sheet.getRange(item.name).delete(Excel.DeleteShiftDirection.up);
        sheet.names.getItem(item.name).delete();
      }
    }

    // Get the attribute names
    const attributeNames = getAttributesInOrder([], currentMeta);

    // Get the tags of all attributes
    const allTags = getAllTags(tagsRng, attNamesRange);

    // Create the dropdown columns
    let col = firstItemsColumn.columnIndex;
    for (const attName of attributeNames) {
      const tags = allTags[attName]; // The tags of the current attribute

      // itemsObj is the items of the current attribute defined in the meta
      // Items are used to define the items of dropdowns or checkboxes
      const itemsObj = currentMeta?.attributes?.[attName]?.items;
      // itemsFromObj is a reference to another attribute which has the items of the current attribute
      const itemsFromObj = currentMeta?.attributes?.[attName]?.items_from;

      // If the current attribute has items (for example dropdown or checkbox items), add them
      if (itemsObj !== undefined || itemsFromObj !== undefined) {
        const items = {};
        // If creating a new column to store the items, or use an existing column
        createOrUseDropdownColumn(
          context,
          sheet,
          attName,
          items,
          itemsObj,
          itemsFromObj,
          tags,
          col,
          currentMeta
        );
        col += 1;
      }
    }

    // Add ...
    //const attributeNames = getAttributesInOrder([], currentMeta);

    //const activeCell = context.workbook.getActiveCell();

    //protectSheet(context, sheet); // Protect sheet
    await context.sync();
  });
};

// ***
// *** Events ***
// ***

// On sheet change event function
export const onSheetChange = async (event) => {
  return; // temporary disable
  if (getGlobalDisableEvents() === true) return;
  //const disableEventsCopy = getGlobalDisableEvents();
  //setGlobalDisableEvents(true); //disableEvents = true;
  console.log("Start..."); // Temporary log. This line will be deleted after testing
  await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const range = sheet.getRange(event.address);
    // count blank cells in range
    const blankCellCount = context.workbook.functions.countBlank(range);
    blankCellCount.load("value");
    // count total cells in range
    const cellCount = range.load("cellCount");
    await context.sync();
    console.log("sheet change: " + event.address);
    //  detect if selected range was cleared
    // if total cell count is equal to blank cell count, the range has been cleared. Therefore, return
    if (cellCount.cellCount === blankCellCount.value) {
      console.log("Content Cleared, return");
      return;
    }
    // Find first cell col and row in range
    // col and row are zero based
    const col = range.load("columnIndex");
    const row = range.load("rowIndex");
    const lastRow = range.getLastCell().load("rowIndex"); //get last row index of event range
    await context.sync();
    //console.log("First cell column index: " + col.columnIndex);
    //console.log("First cell row index: " + row.rowIndex);
    // Hajir: I think "firstAttributeCol" needs to be set before proceeding, its value at this point is: 7 is it correct?
    /*console.log(
	  "firstAttributeCol: " +
		firstAttributeCol +
		"    firstDataRow: " +
		firstDataRow
	);*/
    // Return if the changed cell is before the first attribute column
    /*if (col.columnIndex < firstAttributeCol) {
	  console.log("returning because: before the attribute column"); // May be this is redundant
	  return;
	}
	// Return if the changed cell is before the first data column
	if (row.rowIndex < firstDataRow) {
	  console.log("returning because: before the first data column"); // May be this is redundant
	  return;
	}*/
    // return if the changed cell is outside of the table
    const table = sheet.tables.getItemAt(0);
    await context.sync(); // if not here results in uncaught error in excel javascript file
    const tbl_range = table.getDataBodyRange();
    await context.sync();
    // get bounding box of table data body range
    const tbl_top = tbl_range.load("rowIndex");
    const tbl_left = tbl_range.load("columnIndex");
    const tbl_colCount = tbl_range.load("columnCount");
    const last_cell = tbl_range.getLastCell();
    await context.sync();
    const tbl_bottom = last_cell.load("rowIndex");
    const tbl_right = last_cell.load("columnIndex");
    await context.sync();
    // check if first cell is in table's data body range bounding box
    if (
      tbl_top.rowIndex <= row.rowIndex &&
      row.rowIndex <= tbl_bottom.rowIndex + 1 && //including one row below the table
      // to reproduce the endless onSheetChange events, remove the "+ 3"
      tbl_left.columnIndex + 3 <= col.columnIndex && //exclude first three table columns to prevent false change triggers
      col.columnIndex <= tbl_right.columnIndex
    ) {
      // First cell is in table's data body range bounding box
      console.log("inside Range");
    } else {
      console.log("Outside Range");
      return;
    }
    // Unprotect sheet
    await unprotectSheet(context, sheet);
    // Find the ID in event
    const idRng = sheet.getRange(
      convertNumberToLetter(idColumn) +
        (row.rowIndex + 1) +
        ":" +
        convertNumberToLetter(idColumn) +
        (lastRow.rowIndex + 1)
    ); // e.g.: "B3:B3" or "B3:B11"
    idRng.load("values");
    const actionRng = sheet.getRange(
      convertNumberToLetter(actionsColumn) +
        (row.rowIndex + 1) +
        ":" +
        convertNumberToLetter(actionsColumn) +
        (lastRow.rowIndex + 1)
    ); // e.g.: "D3:D3" or "D3:D11"
    await context.sync();
    //actionRng.load("values");
    const newActions: string[][] = [];
    for (let i = 0; i < idRng.values.length; i++) {
      if (idRng.values[i][0]) {
        newActions.push([actions.sync]);
      } else {
        newActions.push([actions.add]);
        // Pre polulate the new row for example put default values
        //prePopulateRow(sheet,row.rowIndex, currentMeta);
      }
    }
    // set new actions
    actionRng.values = newActions;
    //resize table
    if (row.rowIndex > tbl_bottom.rowIndex) {
      const newTbl_rngString =
        firstTableCol +
        firstDataRow +
        ":" +
        convertNumberToLetter(
          convertLetterToNumber(firstTableCol) + tbl_colCount.columnCount - 1
        ) +
        (lastRow.rowIndex + 1); // e.g.: "B3:H15"
      table.resize(newTbl_rngString);
      console.log("table resized. " + newTbl_rngString);
    }
    // Protect sheet
    //await protectSheet(context, sheet);
  });
  //console.log("disableEvents = false"); // Temporary log. This line will be deleted after testing
  //setGlobalDisableEvents(disableEventsCopy); //disableEvents = disableEventsCopy;
};

// On sheet activate event function
export const onActivated = async (event): Promise<string> => {
  // Return the name of the activated sheet
  if (event?.type === "WorksheetActivated") {
    return await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      sheet.load("items/name");
      await context.sync();
      return sheet.name;
    });
  }

  return "";
};

// ***
// *** Temporary Functions ***
// ***

export const temporarySampleData = async () => {
  /*
// Insert a copy of an existing workbook into the current one
// sRC: https://docs.microsoft.com/en-us/office/dev/add-ins/excel/excel-add-ins-workbooks#insert-a-copy-of-an-existing-workbook-into-the-current-one

// Retrieve the external workbook file and set up a `FileReader` object. 
let myFile = document.getElementById("file");
let reader = new FileReader();
reader.onload = (event) => {
    Excel.run((context) => {
        // Remove the metadata before the base64-encoded string.
        let readerResult = reader?.result?.toString();
		let startIndex;
		if (readerResult) startIndex = readerResult.indexOf("base64,");
        let externalWorkbook = reader?.result?.toString();//
		if (externalWorkbook) externalWorkbook = externalWorkbook.substr(startIndex + 7);
            
        // Retrieve the current workbook.
        let workbook = context.workbook;
            
        // Set up the insert options. 
        let options = { 
            sheetNamesToInsert: [], // Insert all the worksheets from the source workbook.
            positionType: Excel.WorksheetPositionType.after, // Insert after the `relativeTo` sheet.
            relativeTo: "Sheet1" // The sheet relative to which the other worksheets will be inserted. Used with `positionType`.
        }; 
            
         // Insert the new worksheets into the current workbook.
         if (externalWorkbook) workbook.insertWorksheetsFromBase64(externalWorkbook, options);
         return context.sync();
    });
};
// Read the file as a data URL so we can parse the base64-encoded string.
//if(myFile && myFile.files[0]) reader.readAsDataURL(myFile.files[0]);
return;
*/
  await getSelectedRowsId(); //refreshActiveRow(); //insertBlankRow();
  return;

  // Insert some sample data
  return await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    //const sheets = context.workbook.worksheets;

    //const tbl2 = sheet.tables.getItemAt(0);
    //tbl2.rows.getItemAt(3).delete();
    //await context.sync();
    //return;

    //const dataTbl = sheet.tables.getItemAt(0); //.load(["rowCount"]);
    //dataTbl.columns.getItemAt(12).getDataBodyRange().numberFormat = [[ "0.00" ]];
    const activeCell = context.workbook.getActiveCell();
    activeCell.load("numberFormat");
    await context.sync();
    console.log("Active Cell Numberformat: ", activeCell.numberFormat);
    return;

    await selectOrClearCellWithError("previous");
    console.log("....");
    await selectOrClearCellWithError("next");
    console.log("....");
    //await selectOrClearCellWithError("clearActive");
    console.log("....");
    await selectOrClearCellWithError("clearAll");
    return;

    /*
	await manageCellWithError(context, sheet, true, "$E$5");
	await manageCellWithError(context, sheet, true, "$E$6");
	await manageCellWithError(context, sheet, false, "$E$6");
	await manageCellWithError(context, sheet, false, "$E$7");
	await manageCellWithError(context, sheet, true, "$E$5");
	await manageCellWithError(context, sheet, true, "$E$8");
	return;
	*/

    const tbl = sheet.tables.getItemAt(0); //.load(["rowCount"]);
    const bdy = tbl.getDataBodyRange();
    bdy.load();
    await context.sync();

    //bdy = bdy.values;

    lastTableRow = firstTableRow + bdy.values.length;

    //console.log("rows count: ", firstTableRow, lastTableRow);

    // Temporary create actions for testing purposes
    sheet.getRange("D6").values = [[actions.sync]];
    sheet.getRange("D8").values = [[actions.add]];
    sheet.getRange("D10").values = [[actions.delete]];
    sheet.getRange("D13").values = [[actions.clear]];
    sheet.getRange("D18").rowHidden = true;
    sheet.getRange("D" + (lastTableRow + 1)).values = [[actions.sync]];
    sheet.getRange("D" + (lastTableRow + 2)).values = [[actions.add]];
    sheet.getRange("D" + (lastTableRow + 3)).values = [[actions.delete]];
    sheet.getRange("D" + (lastTableRow + 4)).values = [[actions.clear]];
    sheet.getRange("E" + (lastTableRow + 4)).values = [["new"]];
    sheet.getRange("H" + (lastTableRow + 4)).values = [["new2"]];
    sheet.getRange("D" + (lastTableRow + 5)).values = [[actions.add]];
    sheet.getRange("D" + (lastTableRow + 5)).rowHidden = true;
    lastTableRow = lastTableRow + 5;
  });
};

// Check if the string is JSON.
// ??? To do for Hafez. Better solution? Lodash?
function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

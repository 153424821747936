/* global Excel */

// About this file:
// This file includes script which is common among templates

import {
  nameRangeWithWbScope,
  addWorksheetIfNotExist,
  getValuesOfNamedRange,
  sheetExists,
} from "@/utilities/general-excel-functions";

import {
  onActivated as onActivatedOfDefaultTemplate,
  onSheetChange as onSheetChangeOfDefaultTemplate,
} from "@/utilities/default-template";

// ***
// *** Global Variables & Constants ***
// ***

// -- Language & Translations --
const language = ""; // The selected language
const defaultLanguage = "en"; // The default language
const languageRangeName = "rng_language"; // The name of the range including the selected language
const languageRngAddress = "B2"; // The address of the language cell in the temporary storage sheet

// -- API URL --
const apiUrlRangeName = "rng_api_url"; // The name of the range including the API URL
let apiUrl = ""; // The selected API URL
const defaultApiUrl = "???"; // The default API URL
const apiUrlRngAddress = "B3"; // The address of the API URL cell in the temporary storage sheet

// -- Sheets --
const sheetPassword = "Addin"; // Sheet Password
const tempStorageShName = "Temp~Storage"; // The temporary storage sheet

// -- Disable Events --
// Global variable to disable events if true
let disableEvents = false;
export const getDisableEvents = () => disableEvents;
export const setDisableEvents = (b: boolean) => {
  disableEvents = b;
};

// -- Actions --
export const actions = Object.freeze({
  add: "Add",
  sync: "Sync",
  delete: "Delete",
  clear: "Clear",
  all: "All",
});

// -- Form Inout Types --
export const inputTypeTagPrefix = "form:"; // In the attribute tags, input types are defined with this prefix
export const inputTypes = Object.freeze({
  select: "select",
  checkbox: "checkbox",
  radio: "radio",
  text: "text",
  number: "number",
  textarea: "textarea",
  color: "color",
  date: "date",
  datetimeLocal: "datetime-local",
  email: "email",
  month: "month",
  password: "password",
  range: "range",
  search: "search",
  tel: "tel",
  time: "time",
  url: "url",
  week: "week",
});

// ***
// *** Language and Translations ***
// ***

/*
// Get language
export const getLanguage = () => language || defaultLanguage;

// Set language
export const setLanguage = (lang) => {
  language = lang;
};
*/

// ***
// *** API URL ***
// ***

// Set API URL and client ID
export const setApiInfo = async (url) => {
  const re = await setValueOnTmpStSh(url, apiUrlRngAddress);
  apiUrl = url;
};

// Get the selected API and client ID
export const getApiInfo = async () => {
  // If the API URL is already defined in the global scope, return it.
  if (apiUrl !== "") {
    console.log("Returning the apiUrl defined in the global scope");
    return apiUrl;
  }
  // Otherwise, get the language from the temporary storage sheet
  const val = await getValuesOfNamedRange(apiUrlRangeName);

  // If no language is found, return the default language
  if (val === false) {
    console.log("Returning the default API URL");
    return defaultApiUrl;
  }

  return val[0][0];
};

// ***
// *** Temporary Storage Sheet ***
// ***

// Create and design the temporary storage sheet (sh) like put table headers
// Return true if sheet is created and designed
export const designTempStSh = async (context, sheets) => {
  // Create the temporary storage sheet if it does not exist
  const sheetCreated = await addWorksheetIfNotExist(tempStorageShName);
  if (!sheetCreated) return false;

  // Select the temporary storage sheet
  const tempStorageSh = context.workbook.worksheets.getItem(tempStorageShName);
  const tbl = tempStorageSh.tables.add("A1:B1", true /*hasHeaders*/);
  tbl.getHeaderRowRange().values = [["ID", "Value"]];

  // Add the language row
  tbl.rows.add(null /*add rows to the end of the table*/, [["language", ""]]);

  // Add the API URL row
  tbl.rows.add(null /*add rows to the end of the table*/, [["api_url", ""]]);

  await nameRangeWithWbScope(languageRngAddress, languageRangeName);
  return true;
};

// Set a value in the temporary storage sheet
// Return true if the value is set successfully.
// Inputs:
//	+ val: The value to set
//  + rngAddress: The address/name of the range to include the value
export const setValueOnTmpStSh = async (val, rngAddress) => {
  return await Excel.run(async (context) => {
    disableEvents = true; // Temporary disable events
    const sheets = context.workbook.worksheets;
    sheets.load("items");
    await context.sync();

    // Check the temporary storage sheet exists. If not, create it
    const sheetExistence = await sheetExists(tempStorageShName);
    if (!sheetExistence) {
      const sheetDesigned = await designTempStSh(context, sheets); // Create & design the temporary storage sheet
      if (!sheetDesigned) return false;
    }

    // Set the value in the requested range
    const tempStorageSh =
      context.workbook.worksheets.getItem(tempStorageShName);
    const rng = tempStorageSh.getRange(rngAddress);
    rng.values = [[val]];
    await context.sync();
    disableEvents = false;
    return true;
  });
};

// ***
// *** Sheet and range functions ***
// ***

// Unprotect sheet
export const unprotectSheet = async (context, sheet) => {
  // This function is not working. Error is raised for the await line.
  //if (sheet.protection.protected) {
  sheet.protection.unprotect(sheetPassword);
  await context.sync();
  //}
};

// Protect sheet
// pre-req: The sheet should not be protected
export const protectSheet = (context, sheet) => {
  sheet.protection.protect(
    {
      allowAutoFilter: true,
      allowDeleteRows: false,
      allowEditObjects: true,
      allowEditScenarios: true,
      allowFormatCells: true,
      allowFormatColumns: true,
      allowFormatRows: true,
      allowInsertHyperlinks: true,
      allowInsertRows: false,
      allowPivotTables: true,
      allowSort: true,
    },
    sheetPassword
  );
  //await context.sync();
};

export const initialize = async (
  setCurrentResource: (name: string) => void
) => {
  await Excel.run(async (context) => {
    const sheets = context.workbook.worksheets;
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    sheet.load("items/name");
    await context.sync();
    setCurrentResource(sheet.name);
    // Register events
    sheets.onChanged.add(onSheetChangeOfDefaultTemplate);
    sheets.onActivated.add((evt) =>
      onActivatedOfDefaultTemplate(evt).then((sheetName) =>
        setCurrentResource(sheetName)
      )
    );
  });
};

// ***
// *** General functions ***
// ***

// Combine two arrays and produce an object. The keys of the object are set by keysArr.
// The va;ues of the object are set by valuesArr
// pre-req: keysArr and valuesArr have the same length
export const twoArraysToObject = (keysArr, valuesArr) => {
  const obj = {};
  for (let i = 0; i < keysArr.length; i++) obj[keysArr[i]] = valuesArr[i];
  return obj;
};

// Grouping objects by a property
// Src: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reduce#grouping_objects_by_a_property
/*
export const groupBy = (objectArray, property) => {
  return objectArray.reduce(function (acc, obj) {
    let key = obj[property]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
};
*/
